import * as React from 'react';

import { devLog } from '../util/util';
import config from '../../config';
import { UserContext } from '../providers/UserProvider';
import { fetchDispensary } from '../dispensary/dispensary-utils';
import { useAnalyticsStore } from '../../App';
import PropTypes from 'prop-types';

/**
 * NOTE: The Zyp Run service area (zip codes) are provided
 * by the ADMIN APP (dispensary.delivery_zip_codes).
 * 
 * Everything here is for deliverable session tracking.
 */
export const SERVICE_AREA_ZIP_CODES = [
  "02108", "02109", "02110", "02111", "02112", "02113", "02114", "02115", 
  "02116", "02117", "02118", "02119", "02120", "02121", "02122", "02123", 
  "02124", "02125", "02126", "02127", "02128", "02129", "02130", "02131", 
  "02132", "02133", "02134", "02135", "02136", "02137", "02163", "02196", 
  "02199", "02201", "02203", "02204", "02205", "02206", "02210", "02211", 
  "02212", "02215", "02217", "02222", "02241", "02266", "02283", "02284", 
  "02293", "02297", "02298", "02445", "02446", "02447", "02467", "02169", 
  "02170", "02171", "02269", "02138", "02139", "02140", "02141", "02142",
  "02150", "02143", "02144", "02145", "02148", "02155", "02472", "02474", 
  "02476", "02478"
];

// Used for an error message when delivery is DISABLED
// "We normally deliver to these cities: ...cities, check back later."
export const SERVICE_AREA_CITIES = [
  "Arlington", "Belmont",
  "Boston", "Brookline", "Cambridge",
  "Chelsea", "Quincy", "Somerville",
  "Malden", "Medford", "Watertown"  // => "02148", "02155", "02472"
];

// The Map is an Object in this case
const createMinOrderMap = (valueByZipCodeArray) => {
  return valueByZipCodeArray && valueByZipCodeArray.length
    ? valueByZipCodeArray.reduce((obj, item) => {
        obj[item.zip_code] = item.cents;
        return obj;
      }, {})    
    : {}
}

export const CHECKOUT_PATH = '/checkOut';

export const DispensaryContext = React.createContext();

const DispensaryProvider = ({children}) => {
  
  const dispensaryId = config.ZYP_RUN_DISPENSARY_ID;

  const { trackError } = useAnalyticsStore();
  const { user } = React.useContext(UserContext);
  const [dispensary, setLocalDispensary] = React.useState();
  // Full service area
  const [deliveryZipCodes, setDeliveryZipCodes] = React.useState([]);
  // Currently enabled (usually all)
  const [enabledDeliveryZipCodes, setEnabledDeliveryZipCodes] = React.useState([]);
  // Minimum Order by Zip Code Settings
  const [minOrderByZipCode, setMinOrderByZipCode] = React.useState();

  const [loading, setLoading] = React.useState();

  // Deliver User to /checkOut when their original "continueTo" checkout
  // might get lost in a sign up/sign in flow.
  const [isCheckingOut, setIsCheckingOut] = React.useState(false);

  // We're not currently delivering in:
  const getNoDeliveryLocation = React.useCallback((location) => {
    if (location?.city) {
      return deliveryZipCodes.includes(location?.zip_code)
        ? location.zip_code  
        : location.city;         
    } else {
      return 'your zip code.';
    }        
  }, [deliveryZipCodes]);

  // Handle missing/disabled dispensaries
  const [dispensaryNotFound, setDispensaryNotFound] = React.useState(false);

  const dispensaryCallback = React.useCallback((response) => {   
    if (response.id) {
      devLog('Dispensary Provider: SETTING');
      setLocalDispensary(response);
      const { delivery_zip_codes,
              enabled_delivery_zip_codes,
              minimum_order_value_requirements } = response;
      setDeliveryZipCodes(delivery_zip_codes);
      setEnabledDeliveryZipCodes(enabled_delivery_zip_codes);
      setMinOrderByZipCode(createMinOrderMap(minimum_order_value_requirements));
      setDispensaryNotFound(false);
    } else if (response.status === 404) {
      devLog('Dispensary Not Found');
      setDispensaryNotFound(true);
      trackError(`dispensary_not_found_${dispensaryId}`);
    } else {
      devLog('Dispensary Provider Error: non-400');
      setDispensaryNotFound(true);
      trackError(`dispensary_provider_error_${dispensaryId}`);
    }
    setLoading(false);  
  }, [trackError, dispensaryId]);

  const controllerRef = React.useRef(new AbortController());

  // Fetch Dispensary  
  React.useEffect(() => {
    const controller = controllerRef.current.signal.aborted
      ? new AbortController()
      : controllerRef.current;
 
    if (user && dispensaryId && !dispensary?.id) {
      setLoading(true);
      fetchDispensary(dispensaryId, dispensaryCallback, controller);
    }
    // cancel fetch on unmount
    return () => controller.abort();
  }, [user, dispensaryId, dispensary, dispensaryCallback]);


  return <DispensaryContext.Provider value={{
     dispensary,
     dispensaryId,
     serviceAreaZipCodes: deliveryZipCodes,
     enabledDeliveryZipCodes,
     minOrderByZipCode,
     getNoDeliveryLocation,
     dispensaryNotFound,
     isCheckingOut,
     setIsCheckingOut,
     loading
    }}>
    {children}
  </DispensaryContext.Provider>
}

DispensaryProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default DispensaryProvider;
