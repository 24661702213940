import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import { UserContext } from '../providers/UserProvider';
import { activeController } from '../util/util';
import { fetchMetaData } from '../dispensary/dispensary-utils';

/**
 * This hook allows us to change the default phone verify mode (sms/voice)
 * using the Admin App (so we don't have to redeploy when the SMS service goes down)
 */
const useDefaultPhoneVerifyMethod = () => {

  const { user } = React.useContext(UserContext);
  const [loading, setLoading] = React.useState();
  const [isVoiceDefault, setIsVoiceDefault] = React.useState();
  const { trackError } = useAnalyticsStore();
  
  const controllerRef = React.useRef(new AbortController());

  // Fetch Meta Data
  React.useEffect(() => {
    const controller = activeController(controllerRef);

    const metaDataCallback = (response) => {
      if (response.registration) {
        const { tel_number_verify_default } = response.registration;
        setIsVoiceDefault(tel_number_verify_default === "voice"); // or "sms"
      } else {
        trackError('error_setting_phone_verify_default');
      }
      setLoading(false);
    }

    if (user && typeof isVoiceDefault === "undefined") {
      setLoading(true);
      fetchMetaData(metaDataCallback, controller);
    }
    return () => controller.abort();
  }, [user, isVoiceDefault, trackError]);

  return ({
    loading,
    isVoiceDefault
  });
};

export default useDefaultPhoneVerifyMethod;
