import * as React from 'react';

import { getUrlHashedValue } from '../util/storage-utils';
import PropTypes from 'prop-types';

export const AttributionContext = React.createContext();

const AttributionProvider = ({children}) => {
  
  // Track source of shopping app traffic (especially for partner links)
  const [referralDomain, setReferralDomain] = React.useState();

  // Handle discount code in site link
  const [siteLinkDiscountCode, setSiteLinkDiscountCode] = React.useState();

  // Standard referrer from partner link (https only)
  React.useEffect(() => {
    if (document.referrer && !referralDomain) {
      setReferralDomain(document.referrer);
    }  
  }, [referralDomain]);

  // Listen for referrer message from host
  React.useEffect(() => {
    // Accept messages within iframe from marketing site (/framedLocationSearch)
    const trustedOrigins = ["https://zyprun.com"];
    
    const onMsg = (msg) => {
      if (!trustedOrigins.includes(msg.origin)) return;
      try {
        if (msg.data && !referralDomain) {
          const { referrer } = JSON.parse(msg.data) || {};
          if (referrer) {
            setReferralDomain(referrer);
            document.body.setAttribute('referrer', referrer);      
          }
        }
      } catch(e) {}      
    };
    if (window.top !== window.self) {
      window.addEventListener("message", onMsg);
    } 
    return () => window.removeEventListener("message", onMsg);  
  },[referralDomain]);

  // Finally check for url params/hash on app load for referrers (override existing referrer)
  React.useEffect(() => {
    if (window.location.search) {
      const params = new URLSearchParams(window.location.search);
      // utm_source=Klaviyo (email)
      if (params.has('utm_source')) {
        setReferralDomain(params.get('utm_source')); 
      }
    } else if (window.location.hash) {
      const referrer = getUrlHashedValue('ref');
      if (referrer) {
        setReferralDomain(window.atob(referrer));
      }
    }
  }, []);

  // Handle discount code url
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('zrCode')) {
      setSiteLinkDiscountCode(urlParams.get('zrCode'));
    } else if (urlParams.has('zrcode')) {
      setSiteLinkDiscountCode(urlParams.get('zrcode'));
    } else if (urlParams.has('ZRCODE')) {
      setSiteLinkDiscountCode(urlParams.get('ZRCODE'));
    }
  }, []);

  return  <AttributionContext.Provider value={{
            referralDomain,
            siteLinkDiscountCode,  // shop.zyprun.com?zrCode=FOO20
          }}>
            {children}
          </AttributionContext.Provider>
}

AttributionProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default AttributionProvider;
