import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';
import { getPriceDisplay } from '../bundles/BulkPriceDetails';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    display:'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  salePriceWrapper: {
    justifyContent: 'flex-start',
  },
  amount: {
    color: 'inherit',
  },
  onSale: {
    textDecoration: 'line-through',
  },
  discountedPrice: {
    color: 'var(--zrTextRed)',
    fontStyle: 'italic',
    fontSize: '115%',
    margin: '0 0 0 .375rem'
  },
});


/**
 * Handle display of sale price or bundle price next to original price
 * on product listing cards
 */
const DisplayPriceOnCard = ({
  cost_usa_cents,
  bundle_deal_data,
  discount_data,
}) => {

  const classes = useStyles();
  // The Sale or Quantity Discount/Bundle Price
  const [discountedPrice, setDiscountedPrice] = React.useState();
  const [bundlePrice, setBundlePrice] = React.useState();
  const [bundleQty, setBundleQty] = React.useState();

  React.useEffect(() => {    
    const { cost_usa_cents_discounted } = discount_data || { cost_usa_cents_discounted: 0 };
    if (cost_usa_cents_discounted) {
      setDiscountedPrice(getPriceDisplay(cost_usa_cents_discounted));
    }
    const { values } = bundle_deal_data || {};
    if (values?.length) {
      const { unit_price, quantity } = values[0];
      setBundlePrice(getPriceDisplay(unit_price * quantity));
      setBundleQty(quantity);
    }
  }, [bundle_deal_data, discount_data]);

  return (
    <span className={`${classes.wrapper} ${discountedPrice ? classes.salePriceWrapper : ''}`}>
      <span className={`${classes.amount} ${discountedPrice ? classes.onSale : ''}`}>
        {dollarDisplay(cost_usa_cents)}
      </span>
      <span className={classes.discountedPrice}>
        { discountedPrice
          ? discountedPrice
          : bundleQty
            ? <>{bundleQty} for { bundlePrice }!</>
            : null
        }
      </span>
    </span>
  )
}

DisplayPriceOnCard.propTypes = {
  cost_usa_cents: PropTypes.number.isRequired,
  bundle_deal_data: PropTypes.object,
  discount_data: PropTypes.object,
}

export default  DisplayPriceOnCard;
