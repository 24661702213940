import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import useInterval from '../util/useInterval';
import Alert from '@material-ui/lab/Alert';
import ChatLink from '../intercom/ChatLink';

import styles from '../common/Alert.module.css';

// Delayed Help Message, 1 tick = 1 second delay
const HavingTroubleCallout = ({  
  delaySeconds=10,
  trackingLabel='default'
}) => {
  
  const [value, setValue] = React.useState(0);
  const [showMessage, setShowMessage] = React.useState();
  
  useInterval(() => {
    if (value < delaySeconds ) {
      setValue(value + 1);
    }
  }, 1000, showMessage); // pause on showMessage

  React.useEffect(() => {
    if (value >= delaySeconds) {
      setShowMessage(true);
      trackEvent(`having_trouble_${trackingLabel}_error_msg`);
    }
  }, [value, delaySeconds, trackingLabel]);

  return ( showMessage
    ? <Alert severity="info" className={styles.alert}>
        <div className={styles.alertHdr}>Having trouble?</div>
        We're here to help!, just call us at
        {' '}
        <a className={styles.link} href="tel:+16174050429">(617) 405-0429</a>
        or hit us up <ChatLink>on Chat</ChatLink>
        <div>(during business hours)</div>
      </Alert>
    : null
  )
}

export default HavingTroubleCallout;
