import * as React from 'react';

import DisplayModal from '../common/DisplayModal';
import SecondaryHeader from '../styleguide/SecondaryHeader';
import GoogleMap from '../googlemaps/GoogleMap';
import ErrorBoundary from '../common/ErrorBoundary';
import PropTypes from 'prop-types';

const DispensaryMapModal = ({ modalProps }) => {
  const{ dispensaryName, formattedAddress} = modalProps || {};
  
  return (
    <DisplayModal 
      title="Dispensary Location" 
      trackContext='dispensary_map'>
      <ErrorBoundary>
        <SecondaryHeader isCentered text={dispensaryName} />
        <div style={{textAlign:'center'}}>
          {formattedAddress}
        </div>
        <GoogleMap address={formattedAddress} />
      </ErrorBoundary>
    </DisplayModal>
  );
};

DispensaryMapModal.propTypes = {
  modalProps: PropTypes.object.isRequired
}

export default DispensaryMapModal;
