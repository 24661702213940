import * as React from 'react';

import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import useNextAvailableTimeSlot from './useNextAvailableTimeSlot';

export const CurrentTimeSlotContext = React.createContext({});

/**
 * Consolidates all the "Delivery by 1:00pm" time/zip_code calcs
 * into a provider for each of the many labels on the page.
 * 
 * The useNextAvailableTimeSlot hook handles the recalc interval
 * and visibility state changes
 */
const CurrentTimeSlotProvider = ({children}) => {
  
  const { timeSlotsByDay } = React.useContext(DeliveryOptionsContext);
  const {
    defaultSlot,
    slotLabel,
    isBostonDefault,
    isNextDay, 
    displayTimeSlots,
  } = useNextAvailableTimeSlot(timeSlotsByDay);

  return <CurrentTimeSlotContext.Provider value={{
           defaultSlot,
           slotLabel,
           isBostonDefault,
           isNextDay, 
           displayTimeSlots,
         }}>
           {children}
         </CurrentTimeSlotContext.Provider>
};

export default CurrentTimeSlotProvider;
