import * as React from 'react';

import { UserContext } from '../providers/UserProvider';
import { DiscountAndCreditContext } from '../providers/DiscountAndCreditProvider';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { useAnalyticsStore } from '../../App';
import useSaveCartItems from './useSaveCartItems';
import { SHOPPING_MILESTONES } from '../analytics/analytics-utils';
import { CommerceLoggingContext } from '../analytics/CommerceLoggingProvider';
import PropTypes from 'prop-types';

// Also for useProductPricing
export const bundleItemTotalCents = (item, quantity) => {
  const { bundle_deal_data } = item;
  // Bundle quantity price trumps ALL   
  if (bundle_deal_data && quantity >= bundle_deal_data.minimum_quantity) {
    const bundleMatch = [ ...bundle_deal_data.values]
                            .sort((a,b) => b.quantity - a.quantity) // sort descending
                            .find(val => quantity >= val.quantity);
    if (bundleMatch) {
      return bundleMatch.unit_price * quantity;
    }                                         
  }
  return undefined;
}

// Also for useProductPricing
export const discountedItemTotalCents = (item, quantity) => {
  const { cost_usa_cents_discounted } = item?.discount_data || {};
  if (cost_usa_cents_discounted) {
    return cost_usa_cents_discounted * quantity; 
  }
  return undefined;
}

// Item total for an original price or sale price/bundle item
const itemTotalForQuantity = (item, quantity) => {
  // Bundle quantity price trumps ALL   
  if (quantity > 1 && item.bundle_deal_data?.values) {
    const bundlePriceCents = bundleItemTotalCents(item, quantity);
    if (bundlePriceCents) {
      return bundlePriceCents;
    }
  }
  // Check for Sale Price next 
  const discountedItemTotal = discountedItemTotalCents(item, quantity);
  if (discountedItemTotal) {
    return discountedItemTotal;
  } else {
    // Return regular price
    return item.display_info.cost_usa_cents * quantity;
  }
};

export const CartItemsContext = React.createContext();

/**
 * Item total for a cart item
 */
export const getCartItemTotal = (item) => {
  return itemTotalForQuantity(item, item.quantity);
};

// For add-to-cart button. This calc should NOT consider DEAL price
export const getCartItemTotalDisplay = (product, quantity=1) => {
  return dollarDisplay(itemTotalForQuantity(product, quantity));
};

// For validation: Calc the total item discount (sale item discount) for the order
export const getCartItemDiscount = (cost_usa_cents, discount_data, quantity=1) => {
  const { cost_usa_cents_discounted } = discount_data || { cost_usa_cents_discounted: 0 };
  if (cost_usa_cents_discounted) {
    return (cost_usa_cents - cost_usa_cents_discounted) * quantity;
  } else {
    return 0;
  }
};

// TODO: This is SINGLE SKU ONLY
export const isBulkPriced = (item) => {
  return item.quantity >= item.bundle_deal_data?.minimum_quantity;
}

const CartItemsProvider = ({children}) => {
  
  const { user } = React.useContext(UserContext);
  const { discountDto, windowDiscountCents, aeropayCreditCents } = React.useContext(DiscountAndCreditContext) || {};
  const { trackEvent } = useAnalyticsStore();

  // Commerce Logging
  const { logCommerceAction } = React.useContext(CommerceLoggingContext); 
  // Restore/Save Cart - Registered users only
  const { savedCartItems, setSavedCartItems, saveCart } = useSaveCartItems(user);

  // Carted Items
  const cartItems = React.useRef([]);
  const cartItemCount = React.useRef(0);
  const [itemTotal, setItemTotal] = React.useState(0);
  const [itemDiscountTotal, setItemDiscountTotal] = React.useState(0);
  const [flowerGrams, setFlowerGrams] = React.useState(0);
  const [bulkItems, setBulkItems] = React.useState([]);

  const updateItemTotal = React.useCallback(() => {

    const items = cartItems.current;

    const itemTotalCents = items.map(item => 
      getCartItemTotal(item))
        .reduce((a, b) => {
          return a + b;
        }, 0);

    const itemDiscountTotalCents = items.map(item => getCartItemDiscount(
      item.display_info.cost_usa_cents,
      item.discount_data,
      item.quantity)).reduce((a, b) => a + b, 0);

    const itemCount = items.map(item => item.quantity)
                           .reduce((a, b) => a + b, 0);
                 
    const flowerEquivalentGrams = items.reduce((total, item) => {
      return total + (item.quantity * (item.flower_equivalent_grams || 0)) 
    }, 0);  
    
    return {
      itemTotalCents,
      itemDiscountTotalCents,
      itemCount,
      flowerEquivalentGrams
    };
  }, []);

  /**
   * THIS CALLBACK IS THE PRIMARY RERENDER TRIGGER FOR CONSUMERS
   * as the cartItems are a Ref and itemTotal .etc are state updates
   */
  const updateCartDetails = React.useCallback((cartItems, skipSaveToDB) => {
    const {
      itemTotalCents, 
      itemDiscountTotalCents, 
      itemCount, 
      flowerEquivalentGrams } = updateItemTotal();
    cartItemCount.current = itemCount;
    setItemTotal(itemTotalCents);
    setItemDiscountTotal(itemDiscountTotalCents);
    setFlowerGrams(flowerEquivalentGrams);
    setBulkItems(cartItems.filter(item => isBulkPriced(item)));
    if(!skipSaveToDB) {
      // Save cart to API - but not during cart restore
      saveCart(cartItems);
    }
  }, [updateItemTotal, saveCart]);

  // For use after placing order
  const emptyCart = () => {
    cartItems.current = [];
    updateCartDetails([]);
  };

  // RECALC DEAL PRICING on changes to discounts/credits
  React.useEffect(() => {
    updateCartDetails(cartItems.current, true);
  }, [discountDto, windowDiscountCents, aeropayCreditCents, updateCartDetails]);

  // Add Item or increase quantity of existing carted item
  // TODO: We don't need to pass the item (we can use getCachedItem... from the provider)
  const addItem = React.useCallback((id, item, quantity, skipSaveToDB) => {
    const previouslyCarted = cartItems.current.find(item => item.id === id); 
    const newCart = [...cartItems.current];
    if ( previouslyCarted ) {
      previouslyCarted.quantity += quantity;
    } else {
      const itemCopy = Object.assign({}, item);
      itemCopy.quantity = quantity || 1;
      newCart.push(itemCopy); 
      cartItems.current = newCart;
    }
    updateCartDetails(newCart, skipSaveToDB);
    trackEvent('cart_add_item');
    logCommerceAction(SHOPPING_MILESTONES.ITEM_CARTED);
  }, [trackEvent, logCommerceAction, updateCartDetails]);

  // Add Item or increase quantity of existing carted item
  const removeItem = (id) => {
    const previouslyCarted = cartItems.current.find(item => item.id === id); 
    if ( previouslyCarted ) {
       const newCart = cartItems.current.filter(item => item.id !== id);
       cartItems.current = newCart;
       updateCartDetails(newCart);
    }
    trackEvent('cart_remove_item');
  }; 

  // Increase quantity of an existing carted item
  const increaseQuantity = (id, quantity) => {
    const previouslyCarted = cartItems.current.find(item => item.id === id); 
    if ( previouslyCarted ) {
      previouslyCarted.quantity += quantity;
    }
    updateCartDetails(cartItems.current);
    trackEvent('cart_increase_qty'); 
  };

  // Increase quantity of an existing carted item
  const decreaseQuantity = (id, quantity) => {
    const previouslyCarted = cartItems.current.find(item => item.id === id); 
    if ( previouslyCarted ) {
      if ( previouslyCarted.quantity <= quantity) {
        removeItem(id);
      } else {
        previouslyCarted.quantity -= quantity; 
        updateCartDetails(cartItems.current);
      }
    }
    trackEvent('cart_decrease_qty'); 
  };

  /**
   * Registered Users: Restore cart from API 
   * if we have saved items and local cart is empty
   */ 
  React.useEffect(() => {
    if (savedCartItems.length && !cartItems.current.length) {
      savedCartItems.forEach(item => addItem(item.id, item, item.quantity, true /* skipSaveToDB */));
      setSavedCartItems([]);
    }
  },[savedCartItems, addItem, setSavedCartItems]);

  return <CartItemsContext.Provider value={{
      cartItems: cartItems.current,
      cartItemCount: cartItemCount.current, /* a rerender trigger */
      bulkItems,
      isEmptyCart: cartItems.current.length === 0,
      itemTotal,
      itemTotalDisplay: dollarDisplay(itemTotal),
      itemDiscountTotal,
      itemDiscountTotalDisplay: dollarDisplay(itemDiscountTotal),  
      flowerGrams,
      addItem,
      removeItem,
      increaseQuantity,
      decreaseQuantity,
      emptyCart 
    }}>
    {children}
  </CartItemsContext.Provider>
}

CartItemsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}

export default CartItemsProvider;
