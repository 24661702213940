import * as React from 'react';

import Button from './Button';
import PropTypes from 'prop-types';

import styles from './Button.module.css';

// Min order not met or undeliverable zip code
const DisabledButtonWithReason = ({
  text, 
  reason, // Generally a MUI Alert component
  withClass='',
  msgClass='',
  isFullWidth=false
}) => {

  const btnStyle = isFullWidth 
    ? { minWidth: '100%'}
    : {}

  return (
    <>
      <Button 
        text={text}
        isCentered
        withStyle={btnStyle}
        withClass={withClass} 
        disabled
        handleClick={e => e.preventDefault()} />
      <div className={`${styles.disabledMsg} ${msgClass}`}>
        {reason}
      </div>  
    </>
  );
};

DisabledButtonWithReason.propTypes = {
  text: PropTypes.string.isRequired,
  reason: PropTypes.oneOfType([PropTypes.object,PropTypes.string]).isRequired,
  withClass: PropTypes.string,
  msgClass: PropTypes.string,
  isFullWidth: PropTypes.bool
};

export default DisabledButtonWithReason;
