import * as React from 'react';

const StrongText = ({
  fontWeight=600,
  brandColor,
  noWrap,
  onClick,
  children, 
}) => {

  const textStyle = {
    cursor: onClick ? 'pointer' : 'default',
    textDecoration: onClick ? 'underline' : 'none',
    fontWeight,
    color: brandColor ? 'var(--zrPrimaryDark)' : 'inherit',
    whiteSpace: noWrap ? 'nowrap' : 'normal',
    margin: '0 .25em', 
  }

  return (
    <span onClick={onClick || undefined} style={textStyle}>{children}</span>
  ); 
}

export default StrongText;
