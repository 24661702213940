import React from 'react';

import { useAnalyticsStore } from '../../App';
import { CartItemsContext } from './CartItemsProvider';
import PlaceOrderItem from './PlaceOrderItem';
import ErrorBoundary from '../common/ErrorBoundary'; 
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  savingsMsg: {
    margin: '.25rem 0 0',
    textAlign: 'right',
    color: 'var(--zrPrimaryDark)',
    fontWeight: 600,
  }
});

/**
 * Display the cart items on the Place Order screen
 */
const PlaceOrderItems = () => {

  const classes = useStyles();
  const { trackEvent } = useAnalyticsStore();

  const { cartItems,
          itemDiscountTotal, 
          itemDiscountTotalDisplay, 
          increaseQuantity,
          decreaseQuantity,
          removeItem } = React.useContext(CartItemsContext);

  return ( cartItems.length
    ? <ErrorBoundary> 
        <ul>
          { cartItems.map(item => {
            return <PlaceOrderItem 
              key={item.id} 
              product={item}
              quantity={item.quantity}
              increaseQuantity={() => {
                increaseQuantity(item.id, 1);
                trackEvent('checkout_increase_qty');
              }}
              decreaseQuantity={() => {
                decreaseQuantity(item.id, 1);
                trackEvent('checkout_decrease_qty');
              }} 
              removeFn={() => {
                removeItem(item.id);
                trackEvent('checkout_remove_itm');              
              }} />
          })}
        </ul>
        { itemDiscountTotal > 0 && 
          <div className={classes.savingsMsg}>Total product savings: {itemDiscountTotalDisplay}</div>
        }
      </ErrorBoundary>
    : <span>There are currently no items in your cart.</span>  
  )
}

export default PlaceOrderItems;
