import * as React from 'react';

import { devLog } from '../util/util';
import { UserContext } from '../providers/UserProvider';
import { CurrentTimeContext } from './CurrentTimeProvider';
import { getFormattedTimeLabel } from '../deliveryWindows/scheduled-delivery-utils';
import { getMaxDaysOut, getDefaultSlotForToday, 
         getEarliestOrderTime, getSlotsStartingToday, 
         getDayLabel, getLatestOrderTimeMS } from './timeslot-utils';

// For when afer hours ordering is not allowed
export const STORE_NOT_OPEN_YET = -999;

const useCurrentTimeSlots = (timeSlotsByDay) => {

  // Check for zip_code exclusions on slots
  const { location } = React.useContext(UserContext);
  const { zip_code:user_zip_code } = location || {};

  // Current time is only updated every minute or so 
  const currentTimeMS = React.useContext(CurrentTimeContext);
  
  // Default time slot, -1 when unset or -999 disabled due to hour of day
  const [defaultSlot, setDefaultSlot] = React.useState(-1);
  // Slots for display (given the day of week + days out)
  const [displayTimeSlots, setDisplayTimeSlots] = React.useState();

  // The time after which we need to refresh the UI 
  const staleTimeMS = React.useRef(0);

  // Handle ordering not available at the current time
  // These were implemented for dispensaries that don't accept orders after hours
  const [ordersDisabled, setOrdersDisabled] = React.useState();
  const [ordersEnabledTime, setOrdersEnabledTime] = React.useState('');
  const [ordersEnabledDay, setOrdersEnabledDay] = React.useState('');  

  const markExpiredSlots = React.useCallback((currentDaySlots, currentTimeMS) => {
    currentDaySlots.forEach(slot => {
      const slotCloseMS = getLatestOrderTimeMS(0, slot, currentTimeMS);
      slot.isExpired = slotCloseMS <= currentTimeMS;
    });     
  }, []);

  /**
   * Determine the valid/available delivery time slots based on currentTimeMS
   */
  React.useEffect(() => {
    if (currentTimeMS && timeSlotsByDay &&
        (!displayTimeSlots || currentTimeMS > staleTimeMS.current)) {
      
      // Update the time of last refresh
      staleTimeMS.current = currentTimeMS;

      // e.g. 2 for Tuesday
      const todayNum = new Date(currentTimeMS).getDay();     
      const weeklySlotsStartingToday = getSlotsStartingToday(todayNum, timeSlotsByDay);
      
      // add .isExpired attribute to expired slots
      markExpiredSlots(weeklySlotsStartingToday[0], currentTimeMS);

      // Display only bookable days
      const maxDaysOut = getMaxDaysOut(weeklySlotsStartingToday, currentTimeMS);
      const validSlots =  weeklySlotsStartingToday.slice(0, maxDaysOut);
      setDisplayTimeSlots(validSlots);

      // Get first available slot or -1
      const defaultSlot = getDefaultSlotForToday(validSlots[0], currentTimeMS, user_zip_code);

      // Handle SAME-DAY ORDERING ONLY when we're outside ordering hours
      if (maxDaysOut <= 1) {
        // Loop thru days until we find a day/timeslot when ordering is available
        let openTime, dayIdx = 0;
        while (!openTime && dayIdx < weeklySlotsStartingToday.length) {
          openTime = getEarliestOrderTime(weeklySlotsStartingToday[dayIdx], dayIdx, currentTimeMS);    
          if (!openTime) {
            dayIdx++;  
          }   
        }
        if (openTime > 0 && 
            ((validSlots.length === 0 && defaultSlot === -1) ||  /* store not open yet */
              dayIdx === 1)) {                                   /* store closed for the day  */
          const openAtDate = new Date(openTime);
          setOrdersEnabledTime(getFormattedTimeLabel(openAtDate.getHours(), openAtDate.getMinutes()));
          setOrdersEnabledDay(getDayLabel(currentTimeMS, dayIdx));         
          setOrdersDisabled(true);
        }
      }
     
      /**
       * We're re-rendering the timeslots every 10 seconds or so, so we 
       * need to be careful not to flush user selections 
       */
      if (validSlots.length === 0 && defaultSlot === -1) {
        // Orders not accepted outside biz hours
        setDefaultSlot(STORE_NOT_OPEN_YET);
      } else {
        setDefaultSlot(defaultSlot);
      }

      devLog('*** UPDATE AVAILABLE SLOT DISPLAY! ***');
    }
  }, [ currentTimeMS, staleTimeMS, timeSlotsByDay, markExpiredSlots, displayTimeSlots, user_zip_code ]);  

  return {
    /* Provide slots for display */
    defaultSlot, 
    displayTimeSlots,
    /* Handle orders disabled after hours */
    ordersDisabled,
    ordersEnabledTime,
    ordersEnabledDay, 
  }
};

export default useCurrentTimeSlots;
