import React from 'react';

import { activeController } from '../util/util';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import BasicButton from '../styleguide/BasicButton';
import ErrorIcon from '@material-ui/icons/Error';
import { ZR_PRIMARY_DARK } from '../../constants/colors';

import PropTypes from 'prop-types';

import styles from './CartItem.module.css';

const CartItemAvailabilityCheck = ({
  Id,
  handleRemove,
}) => {
  
  const notAvailableIconStyle = {
    fontSize: '22px', 
    verticalAlign: '-5px', 
    color: ZR_PRIMARY_DARK 
  }
  
  const { getProductAvailability } = React.useContext(ProductPreviewContext);
  
  const [status, setStatus] = React.useState();
  const controllerRef = React.useRef(new AbortController());
  
  React.useEffect(() => {
    const controller = activeController(controllerRef);
    const callback = (response) => {
      if (response.id) {
        setStatus(200);
      } else if (response.status) {
        setStatus(response.status);
      }
    };
    if (Id) {   
      getProductAvailability(Id, callback, controller);
    }
    return () => controller.abort();
  }, [Id, getProductAvailability])
  
  return ( status === 404
    ? <>
        <div className={styles.notAvailableMask}></div>
        <div className={styles.notAvailableWrap}>
          <div className={styles.notAvailableMsg}>
            <ErrorIcon style={notAvailableIconStyle} /> Sorry, this item has just sold&nbsp;out.
          </div>
          <BasicButton text="Remove from bag" handleClick={handleRemove} />
        </div>
      </>
    : null
  )
}

CartItemAvailabilityCheck.propTypes = {
  Id: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default CartItemAvailabilityCheck;
