import * as React from 'react';

import { PromoBannerContext } from './PromoBannerProvider';
import PromoBannerCarousel from './PromoBannerCarousel';
import Spinner from '../common/Spinner';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    margin: '1.5rem 0 2rem',
    minHeight: 150
  }
});

const PromoBannerSection = () => {
  
  const { promoBannerData } = React.useContext(PromoBannerContext);
  const classes = useStyles();

  return (
    promoBannerData?.length 
    ? <div className={classes.wrapper}>
        <PromoBannerCarousel promoBanners={promoBannerData} />
      </div>
    : promoBannerData?.length === 0
      ? null
      : <Spinner inSmallLayout />
  )
}

export default PromoBannerSection;
