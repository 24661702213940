import * as React from "react";

import { devLog } from "../util/util";
import { useAnalyticsStore } from "../../App";
import { UserContext } from '../providers/UserProvider';
import { ProductCategoryContext } from "../providers/ProductCategoryProvider";
import { groupByStandardCategories } from './product-utils';
import useProductsFetch from "../util/useProductsFetch";
import { fetchProductDetail } from "../dispensary/dispensary-utils";

export const ProductPreviewContext = React.createContext({});
/**
 * This provider is used for menu home product carousels
 * 
 * Despite its name, this provider loads ALL the products
 */
const ProductPreviewProvider = ({children}) => {

  const { user } = React.useContext(UserContext);
  const { trackError } = useAnalyticsStore();
  const { productCategories } = React.useContext(ProductCategoryContext);
  const { data:products, loading } = useProductsFetch(user);

  // All products
  const [allProducts, setAllProducts] = React.useState();
  // All dispensary products keyed by category name
  const [productsByCategory, setProductsByCategory] = React.useState();
  // Categories for which we have products
  const [activeCategories, setActiveCategories] = React.useState();

  // Currently used for cart restore
  const getCachedProductById = React.useCallback((id) => {
    const product = allProducts?.length
      ? allProducts.find(product => product.id === id)
      : undefined;
    return product
      // return clone: .quantity mutation!
      ? Object.assign({}, product)
      : undefined;  
  }, [allProducts]);

  // CartItem hits this 3X so we'll debounce the extra requests  
  const requestRef = React.useRef();
  const getProductAvailability = React.useCallback((itemId, callback, controller) => {
    const wrappedCb = (response) => {
      requestRef.current = false;
      callback(response);
    }
    if (user && callback && !requestRef.current) {
      try {
        devLog('fetching product availability');
        requestRef.current = true;
        fetchProductDetail(itemId, wrappedCb, controller);
      } catch(error) {
        devLog(`useProductDetails fetch error ${error}`);
        requestRef.current = false;
        trackError('get_product_availability_error');
      }
    }
  }, [user, trackError]);

  React.useEffect(() => {
    if (products) {
      devLog('ProductPreviewProvider setup!');
      setAllProducts(products);
      const byCategory = groupByStandardCategories(products);
      setProductsByCategory(byCategory);
      if (productCategories) {
        setActiveCategories(productCategories.reduce((active, cat) => {
          if (byCategory.has(cat.display_name)) {
            active.push(cat);
          }
          return active;   
        },[]));
      }
    }
  }, [products, productCategories]);

  return (
    <ProductPreviewContext.Provider value={{
      allProducts,
      productsByCategory,
      activeCategories,
      getCachedProductById,
      getProductAvailability,
      loading
    }}>
      {children}
    </ProductPreviewContext.Provider>
  );
};

export default ProductPreviewProvider;
