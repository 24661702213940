import React from 'react';

import { trackEvent } from '../analytics/tracking';
import { navigateTop } from '../routing/router-utils';
import { UserContext } from '../providers/UserProvider';
import { DispensaryContext, CHECKOUT_PATH } from '../dispensary/DispensaryProvider';
import OrderPricingProvider from '../providers/OrderPricingProvider';
import useIntercomLogging, { INTERCOM_MILESTONES } from '../intercom/useIntercomLogging';
import { regFlowSuccessKey, hasStoredValue } from '../util/storage-utils';
import BodyWrapper, { LayoutTypes } from '../layout/BodyWrapper';
import PlaceOrder from './PlaceOrder';
import PageTitle from '../common/PageTitle';

import PropTypes from 'prop-types';

/**
 * Remember: /checkOut =  PlaceOrderController
 * 
 * Props from DispensaryContext via PlaceOrderController (pickup/delivery)
 */ 
const CheckOut = ({
  dispensaryId,
  dispensaryName,
  returnToPath,
  showRegistrationSuccess
}) => {

  const { user } = React.useContext(UserContext);
  const { setIsCheckingOut } = React.useContext(DispensaryContext);
  const { logIntercomAction } = useIntercomLogging();
  const [registeredUser, setRegisteredUser] = React.useState();

  React.useEffect(() => {
    // Delivery users must sign in or register
    if(user?.isAnonymous) {
      setIsCheckingOut(true);
      const signInOrSignUp = hasStoredValue(regFlowSuccessKey)
         ? '/signIn'
         : '/signUp';
      navigateTop(signInOrSignUp, {
        state: {
          continuePath: CHECKOUT_PATH,
          returnToPath, /* the page we are viewing the cart on */
        }
      });
    }
    if (user && !user.isAnonymous) {
      setIsCheckingOut(false);
      setRegisteredUser(true);
      trackEvent('checkout_start_view');
      logIntercomAction(INTERCOM_MILESTONES.CHECKOUT_DELIVERY);
    // null user ?
    } else {
      setRegisteredUser(false);
    }
  }, [user, setIsCheckingOut, returnToPath, logIntercomAction]);

  return (
    <BodyWrapper pageLayout={LayoutTypes.FullBleed}>
      <PageTitle title="Checkout" />
      {/* 
        NOTE: The OrderPricingProvider needs to reload at checkout 
        to reset the various slot/payment-type discounts
      */}
      <OrderPricingProvider>
        <>
          { registeredUser
            ? <PlaceOrder
                dispensaryId={dispensaryId}
                dispensaryName={dispensaryName}
                returnToPath={returnToPath} 
                showRegistrationSuccess={showRegistrationSuccess} />
            /* redirect to signin / signup */
            : <></>
          }
        </>
      </OrderPricingProvider>    
    </BodyWrapper>
  )
}

CheckOut.propTypes = {
  dispensaryId: PropTypes.string.isRequired,
  dispensaryName: PropTypes.string.isRequired,
  returnToPath: PropTypes.string,        /* via router, initially undefined */
  showRegistrationSuccess: PropTypes.bool
};

export default CheckOut;
