import * as React from 'react';

import { devLog } from '../util/util';
import { auth } from '../../firebase';
import { UserContext } from '../providers/UserProvider';
import { logWithIntercom } from './intercom-utils';

// NOTE: These MUST also be defined in the Intercom dashboard.
export const INTERCOM_MILESTONES = {
  REGISTRATION_COMPLETE: 'Registration_Complete', 
  CHECKOUT_DELIVERY: 'Checkout_Delivery',
  ORDER_DELIVERY: 'Order_Delivery',
  AEROPAY_LINKED: 'Aeropay_Linked',
  PAID_WITH_CASH: 'Paid_With_Cash',
  PAID_WITH_DEBIT: 'Paid_With_Debit',
  PAID_WITH_AEROPAY: 'Paid_With_Aeropay',
  AEROPAY_SIGNUP_CREDIT: 'Aeropay_Credit'
};

// Actions with string values
export const INTERCOM_SEGMENT_KEYS = {
  ORDER_REFERRER: 'Order_Referrer',
  SIGNUP_REFERRER: 'Signup_Referrer'
};
 
const ignoreReferrer = /google|zyprun/;
// Let's not override valuable partner referrer history with google/zyprun-direct session data
export const shouldLogOrderReferrer = (referrer) => {
  return referrer && !ignoreReferrer.test(referrer.toLowerCase());
}

/**
 * Log actions/updates with Intercom for visibility 
 * in the Intercom Dashboard 
 */
const useIntercomLogging = () => {

  // ZR user creation is laggy so we fallback to firebase user
  const { user } = React.useContext(UserContext); 

  const logIntercomAction = React.useCallback((action) => {
    devLog(`INTERCOM LOG: ${action}`);
    const actionObj = {};
    actionObj[action] = true;
    const { displayName, email } = user?.email ? user :  auth.currentUser || {};
    const { creationTime } = user?.metadata || {};
    if (action && displayName && email && creationTime) {
      logWithIntercom(displayName, email, creationTime, actionObj);
    }
  }, [user]);

  const logIntercomValue = React.useCallback((action, value) => {
    devLog(`INTERCOM LOG: ${action} ${value}`);
    const actionObj = {};
    actionObj[action] = value;
    const { displayName, email } = user?.email ? user :  auth.currentUser || {};
    const { creationTime } = user?.metadata || {};
    if (action && typeof value !== "undefined" && displayName && email && creationTime) {
      logWithIntercom(displayName, email, creationTime, actionObj);
    }
  }, [user]);

  return { 
    logIntercomAction, 
    logIntercomValue
  }   
};

export default useIntercomLogging;
