import * as React from 'react';

import { OrderPricingContext } from '../providers/OrderPricingProvider';
import { NUM_FREE_DELIVERIES } from '../orders/useCustomerOrders';
import useOrderPricingCalcs from './useOrderPricingCalcs';
import TaxesTooltip from './TaxesTooltip';
import CheckoutTip from './CheckoutTip';
import PropTypes from 'prop-types';

import styles from './OrderButton.module.css';

const DiscountDetail = ({label, amount}) => (
  <div className={`${styles.checkoutDetail} ${styles.promotedDetail}`}>
    <span className={styles.label}>{label}:</span>
    <span className={styles.amount}>-{amount}</span>
  </div>
)

const DisplayTotal = ({totalDisplay}) => (
  <div className={styles.checkoutTotal}>
    <span className={styles.label}>Total:</span>
    <span className={styles.amount}>{totalDisplay}</span>
  </div>
)

/**
 * Calculate the fees, taxes and total price at checkout FOR DELIVERY
 *
 * All cost components post-order verified 
 */
const FeesTaxesAndTotal = ({
  dispensaryName,
  isCheckOut=true
}) => {

  // The greater of the standard or discount code MIN ORDER
  const { minOrderOrDiscountMinCents } = React.useContext(OrderPricingContext);

  const { 
    isFreeDeliveryOrder,
    deliveryFeeApplied,
    showFreeDeliveryTip,
    freeDeliveryThresholdDisplay,
    deliveryFeeDisplay,
    smallOrderShortfallCents,
    smallOrderShortfallDisplay,
    smallOrderFeeDisplay,
    serviceFeesAndTaxesDisplay,
    preDiscountSubTotalDisplay,
    referCreditCents,
    referCreditDisplay,
    discountCode,
    discountCodeLabel,
    discountCodeCreditCents,
    discountCodeCreditDisplay,
    aeropayCreditCents,
    aeropayCreditDisplay,
    windowDiscountCents,
    windowDiscountDisplay,
    subTotalCents,
    subTotalDisplay,
    totalPriceDisplay
  } = useOrderPricingCalcs();

  const [minOrderMet, setMinOrderMet] = React.useState();
  React.useEffect(() => {
    if (subTotalCents && typeof minOrderOrDiscountMinCents === "number") {
      setMinOrderMet(subTotalCents >= minOrderOrDiscountMinCents);
    }
  }, [subTotalCents, minOrderOrDiscountMinCents]);

  return (
    <div className={styles.toCheckoutForm} style={isCheckOut ? {} : {marginBottom:0}}>
      {/* Show ITEM TOTAL when we have any discounts/credits */}
      { discountCodeCreditCents || windowDiscountCents || referCreditCents || aeropayCreditCents
        ? <div className={styles.checkoutDetail}>
            <span className={styles.label}>Item total: </span>
            <span className={styles.amount}>
              {preDiscountSubTotalDisplay}
            </span>
          </div>
        : null
      }
      { referCreditCents
        ? <DiscountDetail
            label="Refer Credit"
            amount={referCreditDisplay} />
        : null
      }
      { aeropayCreditCents
        ? <DiscountDetail
            label="Aeropay Credit"
            amount={aeropayCreditDisplay} />
        : null
      }
      { discountCodeCreditCents
        ? <DiscountDetail
            label={`Discount (${discountCode} - ${discountCodeLabel})`}
            amount={discountCodeCreditDisplay} />
        : null
      }
      { windowDiscountCents
        ? <DiscountDetail
            label="Delivery Window Discount"
            amount={windowDiscountDisplay} /> 
        : null
      }

      <div className={styles.checkoutDetail}>
        <span className={styles.label}>Subtotal: </span> 
        <span className={styles.amount}>
          {subTotalDisplay}
          {!minOrderMet &&
            <sup className={styles.minOrderSup}>*</sup>
          }
        </span>
      </div>

      <div className={styles.checkoutDetail}>
        <span className={styles.label}>Delivery:</span>
        <span className={styles.amount}>
          { deliveryFeeApplied === 0 
            ? <>
                <span className={styles.priceComment}>
                  { isFreeDeliveryOrder
                    ? `First ${NUM_FREE_DELIVERIES} orders: Free!`
                    : 'Free!'
                  }
                </span>
                <span className={styles.noCharge}>{deliveryFeeDisplay}</span>
              </>
            : <span>{deliveryFeeDisplay}</span>
          }
        </span>
      </div>
      <div className={styles.checkoutDetail}>
        <span className={styles.label}>
          Fees and Estimated Taxes:
          { serviceFeesAndTaxesDisplay
            ? <TaxesTooltip
                dispensaryName={dispensaryName} />
            : null
          }
        </span>
        <span className={styles.amount}>
          {serviceFeesAndTaxesDisplay}
        </span>
      </div>
      {!isCheckOut &&
        <DisplayTotal totalDisplay={totalPriceDisplay} />
      }
      { smallOrderShortfallCents > 0
        ? <div className={isCheckOut ? '' : styles.checkoutTips}>
            <CheckoutTip text={`
              Add ${smallOrderShortfallDisplay} in product to avoid the $${smallOrderFeeDisplay}
              small bag\u00A0fee.`} />
          </div>
        : showFreeDeliveryTip
          ? <div className={isCheckOut ? '' : styles.checkoutTips}>
              <CheckoutTip text={`
                NO DELIVERY FEE on product orders (Subtotal) of
                $${freeDeliveryThresholdDisplay} or more!`} />
            </div>
          : null
      }
      { isCheckOut &&
        <DisplayTotal totalDisplay={totalPriceDisplay} />
      }
    </div>
  );
}

FeesTaxesAndTotal.propTypes = {
  dispensaryName: PropTypes.string.isRequired
};

export default FeesTaxesAndTotal;
