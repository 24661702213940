import React from 'react';

import { flattenedProduct } from '../products/product-utils';
import { handleMissingProductImage } from '../products/product-utils';
import CartProductImage from '../products/CartProductImage';
import DisplayPrice from '../products/DisplayPrice';
import PropTypes from 'prop-types';

import styles from './OrderConfirmation.module.css';

// Order item for orderConfirmation view
const OrderItem = ({
  product,
  quantity,
}) => {
 
  const {
    name,
    category,
    image_url,
  } = flattenedProduct(product);

  const { imgUrl:product_image } = handleMissingProductImage(image_url, category);
  
  return (
    <li className={styles.cartItem}>
      <div className={styles.itemDetails}>
        <div className={styles.topDetails}>
          <div className={styles.itemCount}>x{quantity}</div>
          <div className={styles.itemTitle}>
            {name}
          </div>
        </div>
        <div className={styles.itemPrice}>
          <DisplayPrice
            product={product}
            quantity={quantity} />
        </div>
      </div>
      <div className={styles.imageWrap}>
        <CartProductImage imgUrl={product_image} imgAlt={name} imgClass={styles.itemImg} imgCategory={category.display_name} />
      </div>
    </li>
  )
}

OrderItem.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
};

export default OrderItem;
