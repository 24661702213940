import * as React from 'react';

import config from '../../config';
import { OrderSection } from '../checkout/PlaceOrder';
import { DiscountAndCreditContext } from '../providers/DiscountAndCreditProvider';
import { CREDIT_LABELS } from './DisplayCreditDetails';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  credit: {
    display: 'flex',
    padding: '.75rem .5rem',
    margin: '0 -.5rem',
    backgroundColor: '#ededed', 
  },
  type: {
    flex: 0,
    paddingRight: 8,
    whiteSpace: 'nowrap',
    font: 'var(--zrFontSemi)',
    color: 'var(--zrPrimaryDark)'
  },
  user: {
    flex: 1,
    fontWeight: 400,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '100%'
  },
  amount: {
    flex: 0,
    font: 'var(--zrFontSemi)',
    color: 'var(--zrPrimaryDark)'
  }  
})

const DisplayCreditAtCheckout = () => {
  const classes = useStyles();
  const { activeCredit:credit } = React.useContext(DiscountAndCreditContext);
  return ( config.ENABLE_CREDITS && credit
      ? <OrderSection title="CREDITS">
          <div className={classes.credit}>
            <div className={classes.type}>
              {CREDIT_LABELS[credit.reason]}
            </div>
            <div className={classes.user}>
              { credit.reason === 'referrer'
                ? credit.referral_name /* you received from */
                : credit.referrer_name /* you referred and got from */
              }
            </div>
            <div className={classes.amount}>-{dollarDisplay(credit.value.usa_cents)}</div>
          </div>
        </OrderSection>  
      : null
  );
};

export default DisplayCreditAtCheckout;
