import * as React from 'react';

import { dollarDisplay } from '../providers/OrderPricingProvider';
import BundlePricingTooltip from './BundlePricingTooltip';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  grid: {
    display:'flex',
    flexDirection: props => props.stacked ? 'column' :'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%', 
    paddingBottom: props => props.stacked ? 0 : 8,
  },
  tipWrap: {
    marginTop: -8,
    order: props => props.stacked ? 2 : 1,
  },
  discountedPrice: {
    color: 'var(--zrTextRed)',
    fontStyle: 'italic',
    fontSize: props => props.stacked ? '94%' :'115%',
    fontWeight: 600,
    order: props => props.stacked ? 1 : 2,
  },
});

// Suppress cents if they are 0.
export const getPriceDisplay = (price_in_cents) => {
  const inDollars = price_in_cents % 100 === 0;
  return dollarDisplay(price_in_cents, inDollars);
};

/**
 * Display bulk price teaser and tooltip
 */
const BulkPriceDetails = ({
  product,
  inModal,
  isOrderHistory,
  stacked,
}) => {

  const classes = useStyles({stacked});
  
  const [minQty, setMinQty] = React.useState();
  const [minQtyPrice, setMinQtyPrice] = React.useState();
  
  React.useEffect(() => {
    if (product?.bundle_deal_data) {
      const { bundle_deal_data } = product;
      const minDeal = [...bundle_deal_data.values].sort((a,b) => b.quantity - a.quantity)
                                             .slice(-1).pop();
      setMinQty(minDeal.quantity);
      setMinQtyPrice(getPriceDisplay(minDeal.unit_price * minDeal.quantity));                                       
    }
  }, [product])
  
  return ( minQty
    ? <div className={classes.grid}>
        <div className={classes.tipWrap}>
          <BundlePricingTooltip 
            cost_usa_cents={product.display_info.cost_usa_cents}
            bundle_deal_data={product.bundle_deal_data} 
            inModal={inModal} />
        </div>
        { isOrderHistory
          ? null
          : <div className={classes.discountedPrice}>
              Buy {minQty} for {minQtyPrice}!
            </div>
        }
      </div>
    : null
  )
}

BulkPriceDetails.propTypes = {
  product: PropTypes.object.isRequired,
  inModal: PropTypes.bool,
  isOrderHistory: PropTypes.bool,
  stacked: PropTypes.bool
}

export default  BulkPriceDetails;
