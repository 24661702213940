import * as React from 'react';

import SimpleCarousel, { CarouselStyle } from '../common/SimpleCarousel';
import BannerCarousel from './BannerCarousel';
import PromoBanner from './PromoBanner';
import PropTypes from 'prop-types';

/**
 * This component will display a carousel of promo banners 
 * 
 * @param {array} promoBanners - The active promo banners
 */
const PromoBannerCarousel = ({
  promoBanners=[],
}) => {
  
  const [itemProps, setItemProps] = React.useState();

  React.useEffect(() => {
    const getPropArray = (banners) => {
      const propArray = [];
      banners.forEach(banner => {
        banner.itemKey = banner.id;
        propArray.push(banner);
      });
      return propArray;
    }
    
    if(!itemProps) {
      setItemProps(getPropArray(promoBanners));
    }
  }, [itemProps, promoBanners]);

  return ( itemProps?.length
    ? window.innerWidth < 481
      ? <BannerCarousel
          carouselItemProps={itemProps}
          maxItemCount={6} />
      : <SimpleCarousel
          CarouselComponent={PromoBanner}
          carouselItemProps={itemProps}
          maxItemCount={6}
          trackingLabel="home_banners"
          carouselStyle={CarouselStyle.PROMO_BANNERS} />  
    : null
  );
}

PromoBannerCarousel.propTypes = {
  promoBanners: PropTypes.array.isRequired,
};

export default PromoBannerCarousel;
