import * as React from 'react';

import useProductPricing from './useProductPricing';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  amount: {
    color: 'inherit',
  },
  onSale: {
    textDecoration: 'line-through',
  },
  salePrice: {
    color: 'var(--zrTextRed)',
    fontStyle: 'italic',
    fontSize: '115%',
    margin: '0 0 0 .375rem'
  },
});

// Used for Price sorting
export const getSortPrice = (product) => {
  const { cost_usa_cents_discounted } = product?.discount_data || {};
  return cost_usa_cents_discounted
    ? cost_usa_cents_discounted
    : product?.display_info.cost_usa_cents || 0;
};

/**
 * Handle display of sale price next to original price
 */
const DisplayPrice = ({
  product,
  quantity=1,
}) => {
  
  const classes = useStyles();

  const { priceDisplay, salePriceDisplay } = useProductPricing(product, quantity);

  return (
    <span>
      <span className={salePriceDisplay ? classes.onSale : ''}>
        <span className={classes.amount}>
          {priceDisplay}
        </span>
      </span>
      { salePriceDisplay &&
        <span className={classes.salePrice}>
          {salePriceDisplay}
        </span>
      }
    </span>
  )
}

DisplayPrice.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number,
}

export default  DisplayPrice;
