import * as React from 'react';

import './Fen.module.css';

const template = () => { return (

<div class="nabePage">
  <div class="nabeHero allston">
    <div class="gradient"></div> 
  </div>
  <div class="body">
    <h1 class="hdr">Zyp&nbsp;Run - The #1 Choice for Weed Dispensary Delivery Service in the Allston Area</h1> 
    <div class="btnWrap"><a class="zrButton" target="_blank" rel="noopener noreferrer" href="https://shop.zyprun.com" role="button"> Order Allston Delivery </a></div>
    <h2  class="subHdr">Cannabis Delivery Service in the Allston Area</h2>
    <div class="para">
      <a class="link" href="/weed-delivery-boston/">Boston Areas Served</a> » Allston
    </div>
    <div class="para">
      Welcome to Zyp&nbsp;Run, the Allston Area's most popular cannabis dispensary delivery service. Serving one of Boston's most iconic neighborhoods from our Boston warehouse (located just 6 miles from the Allston Square), we offer the most efficient and convenient delivery service available in your area. Each day (7 days a week) Zyp&nbsp;Run makes hundreds of same-day deliveries in the Allston area and Greater Boston.
    </div>
    <div class="para">  
      At Zyp&nbsp;Run, all of our drivers are Zyp&nbsp;Run employees. They're trained cannabis delivery professionals committed to providing you with fast and efficient same-day cannabis delivery.
    </div>
    <div class="para local">
      Whether you're returning from a game at Fenway, a concert at the MGM Music Hall, or a bike ride along the Charles,
      Zyp&nbsp;Run is here to keep the vibes going with high-quality cannabis products delivered to your doorstep.
    </div>
    <h3 class="subHdr3">Fastest Weed Delivery in the Allston area, Great Product Selection</h3>
    <div class="para">
      At Zyp&nbsp;Run, we provide an extensive selection of top-notch cannabis products including flower, vapes, pre-rolls, edibles, concentrates, tinctures, and topicals. With our extensive curated menu, you'll find the perfect product to suit your needs.
    </div>
    <div class="para local">
      There's so much to do in the Allston area. Whether you're strolling the Back Bay Fens or dining on Fish and Chips at Cornwalls, spend your time doing what you love and leave the
      city driving to us. Our weed delivery service in the Allston area is known for its timeliness and discretion: Our delivery professionals will ensure that your order reaches you during your selected 2-hour delivery window.
    </div>
    <h3 class="subHdr3">Pre-Pay or Pay Upon Delivery</h3>
    <div class="para">
      In addition to Cash and Debit Card payment, we also offer online payment via Aeropay, a bank-linked payment option similar to Venmo&copy; or Zelle&copy; that's accepted for cannabis purchases. Pre-payment means there's just a quick ID check at your door and you're good to&nbsp;go. 
    </div>
    <h3 class="subHdr3">The Allston Area's #1 Local Cannabis Delivery Service</h3>
    <div class="para">
       As the first social equity cannabis delivery service founded in Boston, we're deeply invested in the community. Every day we strive to offer you a simple, efficient and satisfying experience, and this mindset has helped make us the top dispensary delivery service in the Allston area.
    </div>    
    <h3 class="subHdr3">Experience the Zyp&nbsp;Run Difference</h3>   
    <div class="para">
      Shop our <a class="link" target="_blank" rel="noopener noreferrer" href="https://shop.zyprun.com">Online Menu</a> today and see how easy it is to order from the #1 weed delivery service in the Allston area. We deliver to the Allston area from <strong>11am to 9pm daily</strong>, with extended <strong>10am to 9pm hours Thursday thru Sunday</strong>.
    </div>
    <div class="para">  
      Of course, you can always pre-order on our website 24 hours a day, up to 2 days in advance. 
    </div>
    <div class="para">  
      <div><strong>Got a question?</strong></div>
      <div>Call us at <a class="link" href="tel:+16174050429">(617) 405-0429</a></div>
      <div>We're here to help!</div>
    </div>
    <div class="para">
       <div><strong>Prefer chat?</strong></div>
       <div><a class="link" target="_blank" rel="noopener noreferrer" href="https://shop.zyprun.com/chat">Chat with us here</a></div>
      <div>(11am to 9pm EST)</div>
    </div>
    <div class="btnWrap"><a class="zrButton" target="_blank" rel="noopener noreferrer" href="https://shop.zyprun.com" role="button">Order Allston Delivery</a></div>
  </div>
  <div class="nabeHero heroBtm allston">
    <div class="gradientBtm"></div> 
  </div> 
</div>

)}

export default template;
