import * as React from 'react';

import AyrHero from '../../assets/partners/ayr-hero.jpg';
import CrescoHero from '../../assets/partners/cresco-hero.jpg';
import BerkshireRootsHero from '../../assets/partners/berkshireroots-hero.jpg';
import GardenRemediesHero from '../../assets/partners/garden-remedies-hero.jpg';
import GoodFeelsHero from '../../assets/partners/good-feels-hero2.jpg';
import HappyValleyHero from '../../assets/partners/happyvalley-hero.jpg';
import HarborHouseHero from '../../assets/partners/harborhouse-hero.jpg';
import HarborHouseHeroMobile from '../../assets/partners/harborhouse-hero-mobile.jpg';
import HarpoonHero from '../../assets/partners/harpoon-heroTweak.jpg';
import MissionHero from '../../assets/partners/mission-hero.jpg';
import MissionHeroMobile from '../../assets/partners/mission-hero-mobile.jpg';
import RoveHero from '../../assets/partners/rove-hero.jpg';
// import GenericHero from '../../assets/partners/cannabis-hero.jpg';

/**
 * This map is maintained solely for the custom age21 modal content
 * ( for a better partner link handoff )
 */
const DOMAIN_TO_PARTNER_NAME = {
  'ayrmassachusetts.com': 'ayr',
  'berkshireroots.com': 'berkshireroots',
  'crescolabs.com': 'cresco',
  'gardenremedies.com': 'gardenremedies',
  'getgoodfeels.com' : 'goodfeels',
  'happyvalley.org': 'happyvalley',
  'harborhousecollective.com': 'harborhouse',
  'missiondispensaries.com': 'mission',
  'harpoonbrewery.com': 'harpoonbrewery',
};

export const partnerDataFromReferrer = (referrer) => {
  if (referrer) {
    const [ domainMatch ] = Object.keys(DOMAIN_TO_PARTNER_NAME).filter(domain => referrer.indexOf(domain) > -1); 
    return partnerData[DOMAIN_TO_PARTNER_NAME[domainMatch]];
  }
  return undefined;
}

// Static partner data until the API provides this
const partnerData = {  
  "ayr" : {
    name: "Ayr",
    key: "ayr",
    tagline: "An Elevated Dispensary Experience",
    overview: (
      <>  
         <p>
           Welcome to the Ayr Family&mdash;a community of passionate advocates 
           dedicated to cultivating an elevated dispensary experience.  From 
           innovative products to customer-first service and great vibes, 
           we're committed to bringing you the best quality, most innovative cannabis products.
         </p>
         <p>For more product information, please email
          {' '}<a href = "mailto:info@ayrmassachusetts.com?subject=Zyp Run User Question">info@ayrmassachusetts.com</a> or call 
          {' '}<a href="tel:844-420-1140">(844) 420-1140</a>.
        </p>
      </>
    ),
    default_category: 'Flower',
    suppress_home_link: false,         
    logo: '/img/partners/ayr-logo.png',
    hero: AyrHero,
    heroMobile: AyrHero          
  },
  "cresco" : {
    name: "Cresco",
    key: "cresco",
    tagline: "Everyday cannabis",
    overview: `Each of our brands delivers quality, consistent, and approachable cannabis 
               products thoughtfully designed with the customer's needs in mind. Our wide 
               variety of brands is now available to be delivered in Greater Boston via Zyp Run, 
               and our portfolio continues to evolve.`,
    default_category: 'Flower',
    suppress_home_link: false,           
    logo: '/img/partners/cresco-rounded-logo.png',
    hero: CrescoHero,
    heroMobile: CrescoHero          
  },
  "berkshireroots" : {
    name: "Berkshire Roots",
    key: "berkshireroots",
    tagline: "Cultivation at Elevation",
    overview: `Berkshire Roots is one of Massachusetts' leading cannabis cultivation
               and dispensary operators providing craft-grown cannabis from Berkshire County, 
               the highest elevation in the state. The company's team members in cultivation, 
               extraction lab, and infused products are considered amongst the best in the state. 
               Strict quality control and lab testing ensure that patients and consumers alike enjoy the 
               ultimate cannabis experience no matter the product type. Berkshire Roots has multiple award-winning products
               to choose from and, through our Zyp Run partnership, you can experience the high-quality 
               taste of the Berkshires, delivered to your door.`,
    default_category: 'Flower', 
    suppress_home_link: false,          
    logo: '/img/partners/berkshireroots-logo.png',
    hero: BerkshireRootsHero,
    heroMobile: BerkshireRootsHero          
  },
  "goodfeels" : {
    name: "Good Feels",
    key: "goodfeels",
    tagline: "Good Feels feels good",
    overview: (
      <>
        <p>GREAT NIGHTS. EVEN BETTER MORNINGS.</p>
        <p>Good Feels seltzers and beverage enhancers were dreamt up 
          when we realized a night of drinking just didn't *feel good* anymore.
        </p>  
        <p>
          With no alcohol and all the flavor of a deliciously carbonated seltzer, 
          our infused beverages - made with pure cannabis extractions &mdash; provide a 
          balance of CBD and THC in a crisp sparkling beverage. A better alternative 
          to drinking &amp; smoking. Helping you become a better you.
        </p>  
        <p>
          Our cannabis-infused seltzers can provide you with that experience you're looking for, day or night.
        </p>
        <p>
          Why consume Good Feels beverages?
        </p>
        <ul>
          <li>*Zero Sugar</li>
          <li>*Fast Acting</li>
          <li>*Zero Calorie</li>
          <li>*Easy Dosing</li>
          <li>*No Hangover</li>
        </ul>
        <p>
          Looking for an on-the-go option to make every drink a Good Feels drink? 
          The Good Feels beverage enhancers are perfect for micro-dosing. 
          They're fast-acting, have a clean, consistent taste, and are easy to 
          control the experience you want.
        </p>
      </>
    ),
    default_category: 'Edibles',
    suppress_home_link: false,         
    logo: '/img/partners/good-feels-logo.png',
    hero: GoodFeelsHero,
    heroMobile: GoodFeelsHero
  },
  "harpoonbrewery" : {
    name: "Harpoon Brewery",
    key: "harpoonbrewery",
    tagline: "Drink Rec. Weed",
    overview: (<>
                Crisp, refreshing, and lightly hopped, Rec.&nbsp;Weed is 
                 a delicious beverage with 5mg of pure THC. Brought to you 
                 by the brewers at Harpoon Brewery, Rec.&nbsp;Weed is made 
                 with Citra hops, real passionfruit puree, and green tea to 
                 make this THC beverage easy-drinking with a clean finish. 
                 Experience the fun of a light buzz without the fear of a 
                 hangover. Like all good things in life, Rec.&nbsp;Weed is 
                 best enjoyed in moderation and with friends.
              </>),
    default_category: 'Edibles', 
    suppress_home_link: false,         
    logo: '/img/partners/harpoon-logo.png',
    hero: HarpoonHero,
    heroMobile: HarpoonHero
  },
  "gardenremedies" : {
    name: "Garden Remedies",
    key: "gardenremedies",
    tagline: "Grown with you in mind!",
    overview: `Garden Remedies operates medical and recreational dispensaries in Newton, 
      Melrose, and Marlborough, all powered by a best-in-class cultivation facility in Fitchburg. 
      Through consistency, quality, and craft, Garden Remedies has become the home of cannabis in 
      Massachusetts, developing each product from plant to person. It's grown with you in mind.`,
    default_category: 'Flower', 
    suppress_home_link: false,         
    logo: '/img/partners/garden-remedies-logo.png',
    hero: GardenRemediesHero,
    heroMobile: GardenRemediesHero
  },
  "happyvalley" : {
    name: "Happy Valley",
    key: "happyvalley",
    tagline: "UNLOCK YOUR EXPERIENCE",
    overview: (
      <>
        Happy Valley's award winning products are now available for delivery in select areas.
        <p><b>2022 High Times Winners!</b></p>
        <p>1st Place Beverages &mdash; Raspberry Lemonade Stir Stix</p>       
        <p>1st Place Hybrid Flower &mdash; End Game Cookies</p>
        <p>1st Place Sativa Flower &mdash; Super Lemon Haze</p>
        <p>2nd Place Concentrates &mdash; White Wedding Live Hash Rosin</p>
        <p>For more product information or questions email 
          {' '}<a href = "mailto:support@happyvalley.org?subject=Zyp Run User Question">support@happyvalley.org</a> or call 
          {' '}<a href="tel:978-515-5600">978-515-5600</a>.
        </p>
      </>),
    default_category: 'Flower',
    suppress_home_link: true,           
    logo: '/img/partners//happyvalley-logo.png',
    hero: HappyValleyHero,
    heroMobile: HappyValleyHero          
  },
  "harborhouse" : {
    name: "Harbor House",
    key: "harborhouse",
    tagline: "Just Buds Growing Buds",
    overview: `Harbor House Collective is a small, local family-owned cannabis company 
               blending community and quality practices.  Beginning from growing flowers 
               in their native Colombia for market distribution throughout the Northeast 
               to generational growth honoring a different kind of flower in their Chelsea 
               facility. The 20+ years of cannabis cultivation experience, hand-picked genetics, 
               and a passion for the plant, the team consistently provides connoisseur-level 
               buds, pre-rolls, hash, and edibles.`,
    default_category: 'Flower',
    suppress_home_link: false,           
    logo: '/img/partners/harborhouse-logo.jpg',
    hero: HarborHouseHero,
    heroMobile: HarborHouseHeroMobile         
  },
  "mission" : {
    name: "Mission",
    key: "mission",
    tagline: "",
    overview: `About Mission: Voted best dispensary in Northshore, advancing the 
               culture of cannabis is at the heart of everything we do at Mission. 
               From superior genetics to proven cultivation and manufacturing 
               methodologies, we are committed to fostering a better cannabis industry 
               for everyone. New to the world of cannabis or a seasoned smoker, Mission 
               knows there are many options for the types of cannabis and ways to 
               consume it. Offering a large portfolio of brands and products at affordable 
               prices, Mission proudly delivers on quality and service for all patients 
               and consumers.`,
    default_category: 'Flower',
    suppress_home_link: false,           
    logo: '/img/partners/mission-logo.png',
    hero: MissionHero,
    heroMobile: MissionHeroMobile          
  },
  "rove" : {
    name: "ROVE",
    key: "rove",
    tagline: "Where Do You Rove?",
    overview: (<>
      <p><b>OUR VISION</b></p>
      <p>At ROVE, we believe in honesty, simplicity and transparency. Our goal is to provide customers with the highest quality products that taste great and are created with them in mind. Welcome to our brand, where the best is always getting better.</p>
      <p><b>OUR PRODUCTS</b></p>
      <p>Our products are made using 100% organically grown cannabis. Creating the ultimate individualized cannabis experience means perfection in every step of the process. From cultivation to cartridge, we do everything in house, and take pride in bringing our consumers high quality, great tasting, transparent products.</p>
      <p><b>OUR STORY</b></p>
      <p>ROVE was born at the intersection of art and science. Our team of long time industry enthusiasts, boasting a combined wealth of experience in cultivation, extraction and laboratory science, joined together with a mission to produce better, tastier, more honest cannabis products.</p>
    </>),
    default_category: 'Vaporizers',
    suppress_home_link: false,         
    logo: '/img/partners/rove-logo-blue.png',
    hero: RoveHero,
    heroMobile: RoveHero     
  }
};

/**
 * Custom hook used to provide static brand partner data
 */  
const useBrandPartnerData = (brandName) => {
    
  const [brandKey, setBrandKey] = React.useState();

  React.useEffect(() => {
    if (brandName) {
      setBrandKey(brandName?.toLowerCase().replaceAll(' ', ''));
    }
  },[brandName])

  return { 
    data: brandKey && partnerData.hasOwnProperty(brandKey)
      ? partnerData[brandKey]
      : {} 
  };
};

export default useBrandPartnerData;
