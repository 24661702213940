import * as React from 'react';

const ChatLink = ({children}) => {

  const showChat = () => {
    if (typeof window.Intercom !== "undefined") {
      window.Intercom('show');
    }
  }

  const linkStyle = {
    cursor: 'pointer',
    margin: '0 .25em',
    textDecoration: 'underline',
    font: 'var(--zrFontSemi)',
    fontSize: 'inherit',
    whiteSpace: 'nowrap',
    color: 'var(--zrPrimaryDark)',
  }

  return (
    <span style={linkStyle} onClick={showChat}>{children}</span>
  )
}

export default ChatLink;
