import * as React from 'react';

import useBundledPriceMap from './useBundledPriceMap';
import { makeStyles } from '@material-ui/core';

import PropTypes from 'prop-types';

const useStyles = makeStyles({
  thresholds: {
    boxSizing: 'border-box',
    padding: '2px 4px',
  },
  threshold: {
    display: 'block',
  },
  savings: {
    color: 'var(--zrTextRed)',
    fontStyle: 'italic',
    fontWeight: 600,
    marginLeft: 8,
  }
});

/**
 * Display bundle pricing thresholds
 */
const BundlePricingThresholds = ({
  cost_usa_cents,
  bundle_deal_data,
  includeSavingsPct,
}) => {

  const classes = useStyles();
  const pricingMap = useBundledPriceMap(cost_usa_cents, bundle_deal_data);

  return ( pricingMap
    ? <div className={classes.thresholds}>
        { Array.from(pricingMap).map(pair => (
            pair[1].is_threshold 
              ? <div key={`qty${pair[0]}`} className={classes.threshold}>
                  Buy
                  {' '} 
                  <strong>{pair[0]}</strong> 
                  {' '}
                  for
                  {' '}
                  <strong>{pair[1].price_display}</strong> 
                  {' '}
                  each. 
                  { includeSavingsPct &&
                    <span className={classes.savings}>Save {pair[1].savings_percent}%!</span>
                  }
                </div>
              : null
        ))}
      </div>
    : null
  )
}

BundlePricingThresholds.propTypes = {
  cost_usa_cents: PropTypes.number.isRequired,
  bundle_deal_data: PropTypes.object,
}

export default  BundlePricingThresholds;
