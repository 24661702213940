import * as React from 'react';

import config from '../../config';
import { activeController, devLog } from '../util/util';
import useCustomerDetails from '../account/useCustomerDetails';
import { ProductPreviewContext } from '../products/ProductPreviewProvider';
import { getSaveCartItemDetails, getCartItems, saveCartItems, clearSavedCartItems } from './order-utils';

/**
 * Save/Restore cart items via the API for use with restoring cart 
 * in new sessions and for triggering abandon cart emails
 */
const useSaveCartItems = (user) => {

  const { 
    zypRunUser,
    emailOptIn: email_opted_in,
    textOptIn: sms_text_opted_in
  } = useCustomerDetails(user);
  const [savedCartItems, setSavedCartItems] = React.useState([]);
  const { allProducts, getCachedProductById } = React.useContext(ProductPreviewContext);   
  const controllerRef = React.useRef(new AbortController());

  // Only currently available cart products can be restored to cart  
  const restoreSavedCartItems = React.useCallback((items) => {
    const availableItems = allProducts?.length
      ? items.map(itm => { 
          const itemClone = getCachedProductById(itm.product_id, itm);
          itemClone.quantity = itm.quantity;
          return itemClone;
        })
      : [];
    devLog(`CART RESTORE: Restoring ${availableItems.length} items`);  
    setSavedCartItems(availableItems);
  }, [allProducts, getCachedProductById]);

  // Fetch saved cart items
  React.useEffect(() => {
    const controller = activeController(controllerRef);
    if (user && !user.isAnonymous) {
      const callback = (response) => {
        const { items } = response;
        if (allProducts?.length && items?.length) {
          restoreSavedCartItems(items);
        }
      };
      getCartItems(callback, controller);
    } else if (!user || user?.isAnonymous) {
      setSavedCartItems([]);
    }
    return () => controller.abort();
     // Be careful with dependencies or cart will restore in unwanted ways
  }, [user, allProducts, restoreSavedCartItems]);

  const saveCart = React.useCallback((cartItems) => {
    // Cart Save is for registered users only
    if (!user || user.isAnonymous) {
      return;
    }
    const controller = activeController(controllerRef);
    if (cartItems.length === 0) {
      // clear the user's saved cart items
      devLog('CART CLEAR: Cart is empty');
      clearSavedCartItems(controller);
      setSavedCartItems([]);
    } else if (cartItems?.length > 0 && zypRunUser) {
      const { id:customer_id, name, email } = zypRunUser || {};
      // Save the user's cart items
      const cartInfo = {
        customer_id,
        name,
        email,
        marketing_communications: {
          email_opted_in,
          sms_text_opted_in
        },
        order_type: 'delivery', 
        dispensary_id: config.ZYP_RUN_DISPENSARY_ID,
        items: getSaveCartItemDetails(cartItems)
      };
      devLog(`CART SAVE: Cart has ${cartItems.length} items`);
      saveCartItems(cartInfo, controller);
    }
    return () => controller.abort();
  }, [user, zypRunUser, email_opted_in, sms_text_opted_in]);

  return {
    savedCartItems,
    setSavedCartItems,
    saveCart
  }
}

export default useSaveCartItems;
