import * as React from 'react';

import { trackEvent } from '../analytics/tracking';
import { navigateTop } from '../routing/router-utils';
import { CartItemsContext } from './CartItemsProvider';
import StrongText from '../common/StrongText';
import { MAX_MA_FLOWER_GRAMS } from './QuantityWarning';
import { deliveryDate, displayDeliveryDate } from '../util/date-utils';
import PropTypes from 'prop-types';

export const OrderValidityContext = React.createContext();

const OrderValidityProvider = ({
  activeOrderDates=[],
  selectedDeliverySlot,
  children
}) => {

  // items is a ref so we need the total here to trigger re-rendering
  const { cartItems, itemTotal, flowerGrams } = React.useContext(CartItemsContext);
  const [cartWarnings, setCartWarnings] = React.useState();
  const [existingSameDayOrder, setExistingSameDayOrder] = React.useState('');

  React.useEffect(() => {
    if (selectedDeliverySlot && activeOrderDates?.length) {
      const targetDeliveryDate = deliveryDate(selectedDeliverySlot.date);
      setExistingSameDayOrder(
        activeOrderDates.includes(targetDeliveryDate)
          ? targetDeliveryDate
          : ''
      )
    }
  }, [activeOrderDates, selectedDeliverySlot]);

  // Item limits
  React.useEffect(() => {
    const warnings = []; 
    if (cartItems.length) {
      // 1) Check quantity limit exceeded
      cartItems.forEach(item => {
        if (item.per_order_max_quantity && 
            item.quantity > item.per_order_max_quantity) {
          warnings.push({
            id: item.id,
            message: `${item.display_info.name} has an order limit of ${item.per_order_max_quantity}`,
            recommendation: 'To place your order, reduce the item quantity'
          });
        }
      });
      if (warnings.length) {
        trackEvent('warn_cart_product_qty_exceeded');
      }
      // 2) Check that order does not exceed 1 OZ.
      if (flowerGrams > MAX_MA_FLOWER_GRAMS) {
        warnings.push({
          id: 'over1oz',
          message: 'Your order exceeds the MA state order limit of 1 ounce (28.3mg)',
          recommendation: 'To place your order, reduce your product quantities'
        });
        trackEvent('warn_cart_exceeds_1oz');
      }
      // 3) Same day order 
      if (existingSameDayOrder) {
        warnings.push({
          id: 'existingOrder',
          message: `You have an existing order for ${displayDeliveryDate(existingSameDayOrder)}.
                    State law limits deliveries to 1 per day.`,
          recommendation: (<>
            To place your order, select a different delivery date.
            {' '}
            <StrongText noWrap onClick={() => navigateTop('/orders')}>View your Orders</StrongText>
          </>)
        });
        trackEvent('warn_existing_same_day_order');
      }
    }
    setCartWarnings(warnings.length ? warnings : undefined);
  },[cartItems, itemTotal, flowerGrams, existingSameDayOrder])

  React.useEffect(() => {
    if (cartWarnings?.length) {
      trackEvent('checkout_cart_warnings');
    }
  }, [cartWarnings])

  return (
    <OrderValidityContext.Provider value={{
     cartWarnings,
    }}>
      {children}
    </OrderValidityContext.Provider>
  )
}

OrderValidityProvider.propTypes = {
  activeOrderDates: PropTypes.arrayOf(PropTypes.string),
  selectedDeliverySlot: PropTypes.shape({
    date: PropTypes.object.isRequired,
    hours: PropTypes.object.isRequired,
  }),
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.array]).isRequired
}
  
export default OrderValidityProvider;
