import * as React from 'react';

import { devLog } from '../util/util';
import { deliveryDate } from '../util/date-utils';
import { retreiveOrdersEndpoint } from '../../constants/api-endpoints';
import useFetch from '../util/useFetch';

export const ACTIVE_STATUSES = ['requested', 'accepted', 'ready-for-pickup', 'in-transit']; 
export const CANCELABLE_STATUSES = ['requested', 'accepted', 'ready-for-pickup'];
export const INACTIVE_STATUSES = ['completed', 'canceled'];

export const ORDER_STATUS = {
  REQUESTED: 'requested',
  ACCEPTED: 'accepted',
  READY_FOR_PICKUP: 'ready-for-pickup',
  IN_TRANSIT: 'in-transit',
  COMPLETED: 'completed',
  CANCELED: 'canceled',
}

// Currently the first 2 are free
export const NUM_FREE_DELIVERIES = 2;

// Clean up the API status text
const STATUS_FOR_DISPLAY = {
  [ORDER_STATUS.READY_FOR_PICKUP]: 'Ready for Delivery'
};

export const statusForDisplay = (status) => {
  return STATUS_FOR_DISPLAY[status] || status;
}

// Create a Date from the order.order_counts_against_date: "2023-04-27"
export const orderCountsAgainstDate = (ambiguousDateString) => {
  const dateInfo = ambiguousDateString.split('-');
  return dateInfo.length > 2 
    ? new Date(`${dateInfo[1]}/${dateInfo[2]}/${dateInfo[0]}`)
    : new Date(0);
};

// Only 1 order per day for a user
const isSameDay = (date1, date2) => {
  return date1.toDateString() === date2.toDateString();
}

export const useCustomerOrders = (user) => {

  // Performance: Let's not download 200 orders...
  const ORDER_HISTORY_LIMIT = 12;

  const { 
    data,
    refetch, 
    ...rest 
  } = useFetch(
    user, 
    retreiveOrdersEndpoint, 
    false,     /* allowAnonymous */
    `${user?.uid}?limit=${ORDER_HISTORY_LIMIT}`  /* urlSuffix */
  );

  const [customerOrders, setCustomerOrders] = React.useState();
  const [activeOrders, setActiveOrders] = React.useState();
  const [priorOrders, setPriorOrders] = React.useState();
  const [isFreeDeliveryOrder, setIsFreeDeliveryOrder] = React.useState();
  const [hasCurrentDayOrders, setHasCurrentDayOrders] = React.useState();
  const [activeOrderDates, setActiveOrderDates] = React.useState();
  const [aeropayOrderCount, setAeropayOrderCount] = React.useState();

  React.useEffect(() => {
    let active, sameDay, prior, completed;
    if (user && typeof(data?.length) === 'number') {
      const sortedOrders = data.sort((a, b) => a.created_at > b.created_at ? -1 : 1);
      setCustomerOrders(sortedOrders);
      // Did the customer order delivery for today?
      sameDay = sortedOrders.filter(order => {
        const deliveryDate = orderCountsAgainstDate(order.order_counts_against_date);
        return order.status !== 'canceled' &&
          isSameDay(deliveryDate, new Date());
      });
      setHasCurrentDayOrders(sameDay.length > 0); 
      
      active = sortedOrders.filter(order => !INACTIVE_STATUSES.includes(order.status)); 
      setActiveOrders(active);
      
      /**
       * Determine which dates a user has active orders for.
       * Shoppers can order deliveries for consecutive days on a single day
       */
      const activeDates = active.reduce((dates, order) => {
        // '8-13-2024'
        dates.push(deliveryDate(order.fulfillment_time_slot.date));
        return dates;
      }, []);
      setActiveOrderDates(activeDates);
       
      prior = sortedOrders.filter(order => INACTIVE_STATUSES.includes(order.status));
      setPriorOrders(prior);
      
      // Free delivery on first order
      completed = sortedOrders.filter(order => order.status === 'completed');
      setIsFreeDeliveryOrder(active.length + completed.length < NUM_FREE_DELIVERIES);

      // The second part of the Aeropay credit check, check all orders
      const aeropayOrders = sortedOrders.filter(order => 
        order.payment_details.processor === "aeropay" &&
        order.status !== 'canceled'
      );
      // Update User info based on current orders (ensure no active/completed orders)
      setAeropayOrderCount(aeropayOrders.length);
    }
    devLog(`useCustomerOrders: Orders:${data?.length}, Active:${active?.length}`);
  }, [user, data]);

  return {
    customerOrders,
    activeOrders,
    priorOrders,
    isFreeDeliveryOrder,
    aeropayOrderCount, // TODO: We could use the User.has_completed_aeropay_order data instead
    hasCurrentDayOrders,
    activeOrderDates,  // Target/Slot delivery dates!
    refetch,
    ...rest,
  };
}

export default useCustomerOrders;
