import * as React from 'react';

import { aeropayPaymentTypeValue, debitPaymentTypeValue } from '../checkout/usePaymentOptionReducer';
import { FormControlLabel, Radio, RadioGroup, FormControl } from '@material-ui/core';
import PaymentFormAerosync from '../account/PaymentFormAerosync';
import DebitCardWarning from '../payment/DebitCardWarning';
import AeropayCreditAtCheckout from '../payment/AeropayCreditAtCheckout';
import PropTypes from 'prop-types';
import styles from './SelectPayment.module.css';

// A single payment option (radio)
const PaymentRadioOption = ({details, handleNonRadioClick}) => {

  const { label, desc, value, tag, Tooltip  } = details;

  const radioStyle = {
    padding: '4px 4px 4px 0',
    color: 'var(--zrPrimaryDark)'
  }

  return ( 
    <>
      <div className={styles.radioGrid}>
        <FormControlLabel
          label={(
            <div className={styles.radioLabelWrap}>
              <span className={styles.radioLabel}>{label}</span>
              <span className={styles.radioDesc}>
                {` \u2013 ${desc}`}
                { tag &&
                  <span className={styles.radioDescTag}>{tag}</span>
                }
              </span>
              {Tooltip &&
                <Tooltip />
              }
            </div>)}
          value={value}
          control={<Radio style={radioStyle} />} 
        />
      </div>
      {/* Aeropay callout */}
      { value === aeropayPaymentTypeValue
        ? <div className={styles.apSetupNote}
               onClick={() => handleNonRadioClick(aeropayPaymentTypeValue)}>
            <PaymentFormAerosync isLinkOnly />
          </div>
        : null
      }  
    </>
  );
};

const SelectPayment = ({
  selectedMethod,
  paymentMethodState,
  updatePaymentMethod,
  formLabelId, // id of related form label
  isAeropayCreditEligible
}) => {

  return (
    <>
      <FormControl>
        { selectedMethod &&
          <RadioGroup
            aria-labelledby={formLabelId}
            name="payment-radio-group"
            defaultValue={selectedMethod}
            value={selectedMethod} 
            onChange={(event) => updatePaymentMethod(event.target.value)}>
            
            {/* NOTE: Aeropay is the default if enabled and user is linked */}
            { paymentMethodState.paymentOptions.map(option => (
                paymentMethodState.enabledMethods[option.enableKey]
                  ? <PaymentRadioOption key={option.value} details={option}
                      handleNonRadioClick={updatePaymentMethod} />
                  : null
            ))}
          </RadioGroup>
        } 
      </FormControl>
      {/* Just putting payment notes in same order as radio options */}
      { paymentMethodState.aeropayFirst
        ? <>
            { isAeropayCreditEligible && <AeropayCreditAtCheckout /> }
            { selectedMethod === debitPaymentTypeValue &&
              <DebitCardWarning />
            }
          </>
        : <>
            { selectedMethod === debitPaymentTypeValue &&
              <DebitCardWarning />
            }
            { isAeropayCreditEligible && <AeropayCreditAtCheckout /> }
          </>
      }
    </>
  );
}

SelectPayment.propTypes = {
  selectedMethod: PropTypes.string.isRequired,
  paymentMethodState: PropTypes.object.isRequired,
  updatePaymentMethod: PropTypes.func.isRequired,
  formLabelId: PropTypes.string.isRequired,
  isAeropayCreditEligible: PropTypes.bool
}

export default SelectPayment;
