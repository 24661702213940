import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

/**
 * PROD
 **/ 
var firebaseConfigProd = {
  apiKey: "AIzaSyDoGzr37czsa5OqMtkhz7A9avgaMzO9bdE",
  authDomain: "ziprun-prod.firebaseapp.com",
  databaseURL: "https://ziprun-prod.firebaseio.com",
  projectId: "ziprun-prod",
  storageBucket: "ziprun-prod.appspot.com",
  messagingSenderId: "483091914269",
  appId: "1:483091914269:web:1b794f2d2b467ac8cbf0f4",
  measurementId: "G-GWYTJKE3HK"
};

/** 
 * QA1 - These are the new zyprun-qa1-shop values: (!)
 **/
const firebaseConfigQA1 = {
  apiKey: "AIzaSyA1RWr8entMmdizW433pWG5V0gN5UMHLcA",
  authDomain: "ziprun-qa1.firebaseapp.com",
  databaseURL: "https://ziprun-qa1.firebaseio.com",
  projectId: "ziprun-qa1",
  storageBucket: "ziprun-qa1.appspot.com",
  messagingSenderId: "669319868205",
  appId: "1:669319868205:web:d0a88a16993672270e2da4", /* (!) */
  measurementId: "G-9P5ZVVXKNF" /* (!) */
};

/**
 * QA2 - These are the new zyprun-qa2-shop values: (!)
 **/
export const firebaseConfigQA2 = {
  apiKey: "AIzaSyDBbJ7OR6MYapqfe_TQTk9RFFUIW492zdE",
  authDomain: "ziprun-qa2.firebaseapp.com",
  databaseURL: "https://ziprun-qa2.firebaseio.com",
  projectId: "ziprun-qa2",
  storageBucket: "ziprun-qa2.appspot.com",
  messagingSenderId: "953555140464",
  appId: "1:953555140464:web:2d6f8953f79522af4999b5", /* (!) */
  measurementId: "G-Y8G8HDQZ7C" /* (!) */
};

/**
 * Configure QA for developmnent/staging, else Production
 * 
 * Staging is just a build param added to package.json in the "scripts" section
 * 
 *   Staging build: npm run-script build:staging2 etc.
 *   Production build: npm run-script build 
 *
 *   Staging currently runs on the demo site
 */

const isStagingBuild = typeof process.env.REACT_APP_STAGING_BUILD !== "undefined" &&
                              process.env.REACT_APP_STAGING_BUILD === "true";

// QA2 is the default auth for web dev. QA1 is generally used for back end development
let firebaseConfig = firebaseConfigQA2;
if (process.env.PUBLIC_URL && process.env.PUBLIC_URL.indexOf('-qa1') > -1) {
  firebaseConfig = firebaseConfigQA1;   
} else if (process.env.NODE_ENV === "production" && !isStagingBuild) {
  firebaseConfig = firebaseConfigProd;  
}

const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
