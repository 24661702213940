import * as React from 'react';

import { CurrentTimeSlotContext } from './CurrentTimeSlotProvider';
import CurrentSlotETAText from './CurrentSlotETAText';
import { makeStyles } from '@material-ui/core';

// Styles for the Carousel header placement
const useStyles = makeStyles({
  deliveryByWrap: {
    font: 'var(--zrFontSemi)',
    fontSize: '14px',
    color: 'var(--zrPrimaryDark)',
    whiteSpace: 'nowrap',
    marginTop: 4,
  },
  boltIcon: {
    verticalAlign: -5,
    paddingRight: 2,
  },
  clockIcon: {
    fontSize: '17px',
    verticalAlign: -3,
    marginRight: 2,
  },
});

const DisplayCurrentSlotETACompact = () => {

  const classes = useStyles();

  const {
    slotLabel,
    isBostonDefault,
    isNextDay,
  } = React.useContext(CurrentTimeSlotContext);

  return ( slotLabel
    ? <CurrentSlotETAText
        slotLabel={slotLabel}
        isBostonDefault={isBostonDefault}
        isNextDay={isNextDay}
        classes={classes} />
    : null
  )
}

export default DisplayCurrentSlotETACompact;
