import * as React from "react";

import Alert from "@material-ui/lab/Alert";
import PropTypes from 'prop-types';

const BulkCartWarning = ({
  discountCode,
  bulkItems, 
  withClass}) => {
  return ( discountCode && bulkItems?.length
    ? <Alert severity="warning" className={withClass ? withClass : ''}>
        <div>
          <strong>Discount Codes</strong> cannot be combined with <strong>bulk-priced items</strong>. 
          To place your order, remove the discount code OR reduce the quantity 
          of your bulk-priced item(s) to the maximum listed below:
        </div>
        <table style={{ marginTop:6, width: '100%'}}>
          <tbody>
            <tr>
              <th style={{textAlign:'left'}}>Item</th>
              <th style={{textAlign:'center'}}>Qty</th>
              <th style={{textAlign:'center'}}>Max</th>
            </tr>
            { bulkItems.map(item => 
              <tr key={item.id}>
                <td style={{textAlign:'left'}}>{item.display_info.name}</td>
                <td style={{textAlign:'center'}}>{item.quantity}</td>
                <td style={{textAlign:'center'}}>{item.bundle_deal_data.minimum_quantity - 1}</td>
              </tr>
            )}
          </tbody>
        </table>
      </Alert>
    : null
  );
};

BulkCartWarning.propTypes = {
  discountCode: PropTypes.string,
  bulkItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
    bundle_deal_data: PropTypes.object.isRequired,
  })),
  withClass: PropTypes.string,
}

export default BulkCartWarning;
