import * as React from 'react';

import { initSingleLocationMap, destroyMap, getFormattedAddress } from '../googlemaps/google-maps-utils';

/**
 * @param {string} address - formatted address string
 * @param {object} addressObj - address in JSON format 
 * @param {number} zoom - map zoom level
 * @param {string} height - e.g. 400px
 */
const GoogleMap = ({
  address, 
  addressObj={}, 
  zoom=16, 
  height='275px'}) => {
  
  const mapId = 'map';
  
  const locationString = address 
    ? address
    : getFormattedAddress(addressObj); 

  React.useEffect(() => {
    const loadDelay = window.google && window.google.maps ? 1 : 1000;
    window.setTimeout(() => {
      initSingleLocationMap(mapId, zoom, locationString);
    },loadDelay);
    // destroy map on unmount
    return () => destroyMap();
  }, [locationString, zoom]);

  return (
    <div id={mapId} style={{height:height, margin:'2rem 0'}}></div>
  );
}

export default GoogleMap;
