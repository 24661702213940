import { auth, analytics } from '../../firebase';
import { logEvent } from 'firebase/analytics';
import { bostonNeighborhoodsByZipCode } from './session_tracking';
import { devLog } from '../util/util';

const PRODUCTION_HOSTS = [
  'shop.zyprun.com', 
  'zyprun-prod-shop.firebaseapp.com',
  'zyprun-prod-shop.web.app'
];

export const NON_BOUNCE_EVENT_TRACKING = 'display_new_page_or_modal';

export const trackUserNavigation = () => {
  const hasNavigated = document.body.getAttribute('data-user-navigation');
  if (!hasNavigated) {
    document.body.setAttribute('data-user-navigation', true);
    trackEvent(NON_BOUNCE_EVENT_TRACKING);
  }
}  

/**
 * trackEvent - log an event with Firebase/Google Analytics 
 * 
 * NOTE: Currently we're only seeing the "action" value in the Firebase console.
 * 
 * @param {string} action --- The click, impression or error source 
 */
export const trackEvent = (action) => {
  // Add platform to action
  const action_label = `shop_${action}`;

  // Disable tracking for internal Zyp Run users for better analytics 
  const isProduction =  PRODUCTION_HOSTS.includes(window.location.host);
  const isInternalUser = auth.currentUser?.email?.toLowerCase().indexOf('zyprun') > -1; 
  if (isProduction && isInternalUser) {
    devLog(`Event suppressed: ${action_label}`);
    return;
  }

  // Suppress logging in development to limit accidental noise
  if (window.location.host.indexOf('localhost') > -1) {
    devLog(`Event suppressed: ${action_label}`);
    // skip actual logging in dev
    return;
  } else if (window.location.host.indexOf('qa') > -1) {
    devLog(`Event logged: ${action_label}`);
  }
  logEvent(analytics, action_label);
};

// Track city and Boston neighborhood
export const trackEventByLocation = (action, location) => {
  const { city, zip_code } = location || {};
  if (city) {
    const cityLabel = (city || 'undefined').toLowerCase().replace(/\s/g, '');
    trackEvent(`${action}_${cityLabel}`);
  }
  // For Boston track neighborhood
  if (bostonNeighborhoodsByZipCode.has(zip_code)) {
    const bostonNeighborhood = bostonNeighborhoodsByZipCode.get(zip_code);
    trackEvent(`${action}_bos_${bostonNeighborhood.trackingLabel}`);
  }
};

/**
 * NOTE: Use trackError from the AnalyticsProvider in components, this version 
 * is just for util scripts  
 */
export const trackError = (errorLabel) => {
  trackEvent(errorLabel);
};

// Format labels for logging 'button click' => button_click
// NOTE: Firebase tracking strings truncate after 30 chars
export const trackingString = (str, maxLength, isMaxFromEnd) => {
  const trackingStr = str ? str.replaceAll(/\W+/g, '_') : 'no_value';
  if (maxLength) {
    return isMaxFromEnd
      ? trackingStr.substring(maxLength > trackingStr.length ? 0 : trackingStr.length - maxLength)
      : trackingStr.substring(0, maxLength);
  } else {
    return trackingStr.toLowerCase();
  }
}

// For events sourced from sponsored carousels, etc.
export const partnerLabel = (product, isSponsored) => {
  const { vendor_id } = product;
  return isSponsored
    ? `p${vendor_id}_`
    : '';  
}

// Short date label for tracking
export const trackingDateFromTS = (timeStamp, isSeconds) => {
  // Handle time stamp in seconds
  const timeStampMS = isSeconds 
    ? timeStamp * 1000
    : timeStamp; 
  // 'Tue, 19 Jul 2022 18:42:21 GMT'
  const trackDate = new Date(timeStampMS).toGMTString();
  const dateItems = trackDate.split(' '); 
  // '19_Jul_2022'
  return `${dateItems[1]}_${dateItems[2]}_${dateItems[3]}`; 
}
