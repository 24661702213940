import * as React from 'react';

import { NotificationsContext } from '../notifications/NotificationsProvider';
import { dollarDisplay } from './OrderPricingProvider';

export const AEROPAY_SIGNUP_CREDIT_CENTS = 1000;

export const DiscountType = {
  FIXED: "fixed",           // fixed $ amount
  PERCENTAGE: "percentage"  // not implemented!
};

export const DiscountAndCreditContext = React.createContext();

/**
 * Provides discount and credit data anywhere we need to do minimum order calcs:
 *   1) Discount Codes
 *   2) Window Discounts
 *   3) Aeropay Credit
 *   4) Refer Credit (cleared on discount code entry)
 */
const DiscountAndCreditProvider = ({children}) => {

  // Available referral credit identified at session start
  const { creditToUse } = React.useContext(NotificationsContext);

  // Discount Codes
  const [discountDto, setDiscountDto] = React.useState();
  const [discountCode, setDiscountCode] = React.useState();
  const [discountCodeType, setDiscountCodeType] = React.useState();
  const [discountCodeValue, setDiscountCodeValue] = React.useState();
  const [discountCodeLabel, setDiscountCodeLabel] = React.useState();
  const [discountCodeMinOrderCents, setDiscountCodeMinOrderCents] = React.useState();
  const [discountCodeExpiryDate, setDiscountCodeExpiryDate] = React.useState();
  // Slot/Window Discount
  const [windowDiscountCents, setWindowDiscountCents] = React.useState(0);
  // Aeropay signup credit
  const [aeropayCreditCents, setAeropayCreditCents] = React.useState(0);

  // Store Discount Code details (but don't calc anything!)
  const addDiscountCode = (discountObj) => {
    const {
      code,
      value_percentage,
      value_usa_cents,
      expires_at,
      minimum_order_cost_cents
    } = discountObj || {};
    if (code && code !== discountCode) {
      setDiscountDto(discountObj);
      setDiscountCode(code);
      setDiscountCodeMinOrderCents(minimum_order_cost_cents);
      setDiscountCodeExpiryDate(new Date(expires_at).toLocaleString('en-US', {
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      })); // error: 'Invalid Date'
      if (value_percentage) {
        setDiscountCodeType(DiscountType.PERCENTAGE);
        setDiscountCodeValue(value_percentage);
        setDiscountCodeLabel(`${value_percentage}% OFF`);
      } else if (value_usa_cents) {
        setDiscountCodeType(DiscountType.FIXED);
        setDiscountCodeValue(value_usa_cents);
        setDiscountCodeLabel(`${dollarDisplay(value_usa_cents)} OFF`);
      }
      // Remove any credit from use in current order
      setActiveCredit(undefined);
    }
  };

  // Remove Discount Code details (but don't calc anything!)
  const removeDiscountCode = () => { 
    setDiscountDto(undefined) ;
    setDiscountCode(undefined);
    setDiscountCodeType(undefined);
    setDiscountCodeValue(undefined);
    setDiscountCodeLabel(undefined);
    setDiscountCodeMinOrderCents(undefined);
    setDiscountCodeExpiryDate(undefined);
    // Reapply any available credit for use in current order
    setActiveCredit(creditToUse);
  };

  // User can choose to apply a credit or a discount code, but not both
  const [activeCredit, setActiveCredit] = React.useState();
  // Set/Restore Credit as needed
  React.useEffect(() => {
    if (creditToUse && !discountCode) {
      setActiveCredit(creditToUse);
    }
  }, [discountCode, creditToUse])

  return (
    <DiscountAndCreditContext.Provider value={{
      // DISCOUNT CODES
      addDiscountCode,
      removeDiscountCode,
      discountDto,
      discountCode,
      discountCodeType,
      discountCodeValue,
      discountCodeLabel,
      discountCodeMinOrderCents,
      discountCodeMinOrderDisplay: dollarDisplay(discountCodeMinOrderCents),
      discountCodeExpiryDate,
      // WINDOW DISCOUNT
      windowDiscountCents,
      setWindowDiscountCents,
      // AEROPAY CREDIT
      aeropayCreditCents,
      setAeropayCreditCents,
      // REFERRAL CREDIT IF APPLICABLE
      activeCredit,
    }}>
      {children}
    </DiscountAndCreditContext.Provider>
  );
};

export default DiscountAndCreditProvider;
