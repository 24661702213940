import * as React from 'react';
import { trackEvent } from '../analytics/tracking';
import { GOOGLE_PLACES_REQUESTED_DATA, parseGooglePlace } from './google-maps-utils';

const WIDGET_LOAD_DELAY = 500; // ms

/**  
 * Handle user selection of the autocomplete suggestions
 * and parse out the address components
 */
const handlePlaceSelect = async (autoComplete, onInputChange, onSelection) => {
  if (autoComplete) {
    // Get place object from the google api
    const addressObject = autoComplete.getPlace();
    // Parse the fields we need: street address, etc.
    if (onSelection) {
      const usableLoc = parseGooglePlace(addressObject);
      // Don't update the input unless user has made a typeahead selection
      if (usableLoc.address) {
        // Append latitude/longitude data
        if (addressObject.geometry?.location) {
          const { lat, lng } = addressObject.geometry.location;
          if (lat && lng) {
            usableLoc.geo_coordinates = {
              latitude: lat(),
              longitude: lng()            
            };
          }
        }
        onInputChange(`${usableLoc.address}, ${usableLoc.city}, ${usableLoc.stateShort} ${usableLoc.zipCode}`);
        onSelection(usableLoc);
        trackEvent('autocomplete_address_select');
      }
    }
  }
};

// The AutoComplete instance reference
let autoComplete;
// The timeout reference
let debounce;

// Don't submit any forms...
const handleEnterKey = (event) => {
  if (event.key === 'Enter') {
    event.preventDefault();
  }
}

/**
 * The initial AutoComplete Widget implementation was double-requesting.
 * This hook was created to reduce fees.
 * 
 * Configure the Google Places AutoComplete widget for US address lookup
 * Make sure instance is destroyed on component unmount
 */ 
const useConfiguredAutoComplete = (inputRef, onInputChange, onSelection) => {
  
  React.useLayoutEffect(() => { 
    const inputElem = inputRef.current;  
    // NOTE: If multiple instances are created the getPlace() call will return undefined
    const createAutoComplete = (inputRef, onInputChange, onSelection) => {
      if (window.google?.maps) {
        autoComplete = new window.google.maps.places.Autocomplete(
          inputRef.current,
          { types: [("geocode")], componentRestrictions: { country: "us" } }
        );
        // Specify the data we want fromthe API: Address, GeoCoords
        autoComplete.setFields(GOOGLE_PLACES_REQUESTED_DATA); 
        // add a listener to handle when a place is selected
        autoComplete.addListener("place_changed", () =>
          handlePlaceSelect(autoComplete, onInputChange, onSelection)
        );
        inputElem?.addEventListener('keydown', handleEnterKey);
      }
    }  
    window.clearTimeout(debounce);
    debounce = window.setTimeout(() => {
      createAutoComplete(inputRef, onInputChange, onSelection);
    }, WIDGET_LOAD_DELAY);

    // On unmount destroy AutoComplete instance
    return () => { 
      autoComplete = undefined;
      window.clearTimeout(debounce);
      inputElem?.removeEventListener('keydown', handleEnterKey);
    }
  }, [inputRef, onInputChange, onSelection])
   
  // We don't need to return anything
  // return autoComplete;
};

export default useConfiguredAutoComplete;
