import * as React from 'react';

import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import useNextAvailableTimeSlot from './useNextAvailableTimeSlot';
import CurrentSlotETAText from './CurrentSlotETAText';
import { makeStyles } from '@material-ui/core';

// Styles for Sign Up placement (in Mobile Address bar)
const useStyles = makeStyles(theme => ({
  deliveryByWrap: {
    position: 'absolute',
    top: 4,
    left: 0,
    right: 0,
    padding: '8px 0',
    font: 'var(--zrFontSemi)',
    color: 'var(--zrPrimaryDark)',
    backgroundColor: '#fff',
    whiteSpace: 'nowrap',
  },
  '@global': {
    '#ETA_BAR': {
      display: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      'body[data-route="signUp"] #ETA_BAR': {
        display: 'block', /* Only shown on mobile /signUp page */
      }
    }
  },
  deliveryText: {
    color: '#000',
  },
  /*
  timeLabel: {}
  */
  boltIcon: {
    verticalAlign: -6,
    paddingRight: 3,
    '& svg': {
      height: '24px !important',
    }
  },
  clockIcon: {
    fontSize: '19px',
    verticalAlign: -3,
    marginRight: 3,
  },
}));

const DisplayCurrentSlotETABar = () => {

  const classes = useStyles();

  const { timeSlotsByDay } = React.useContext(DeliveryOptionsContext);
  const {
    slotLabel,
    isBostonDefault,
    isNextDay,
  } = useNextAvailableTimeSlot(timeSlotsByDay);

  return ( slotLabel
    ? <div id="ETA_BAR">
        <CurrentSlotETAText
          slotLabel={slotLabel}
          isBostonDefault={isBostonDefault}
          isNextDay={isNextDay}
          classes={classes} />
      </div>  
    : null
  )
}

export default DisplayCurrentSlotETABar;
