import * as React from 'react';

import { devLog } from '../util/util';
import useInterval from '../util/useInterval';

export const CurrentTimeContext = React.createContext();

const UPDATE_INTERVAL_MS = 30000;

// Update the time for use in Time Slot selection every 30s or so
const useCurrentTime = () => {
  const [time, setTime] = React.useState(new Date().getTime());
  
  useInterval(() => {
    setTime(new Date().getTime());
    devLog('CURRENT TIME PROVIDER UPDATE');
  }, UPDATE_INTERVAL_MS);

  return time;   
};

const CurrentTimeProvider = ({children}) => {
  const currentTimeMS = useCurrentTime();
  return <CurrentTimeContext.Provider value={currentTimeMS}>
           {children}
         </CurrentTimeContext.Provider>
}

export default CurrentTimeProvider;
