import * as React from 'react';

import { DeliveryOptionsContext } from '../providers/DeliveryOptionsProvider';
import { daysOfWeek } from '../util/date-utils';
import { formattedHours } from './StoreInfo';
import { Grid } from '@material-ui/core';
import Header, { HeaderSize } from '../styleguide/Header';
import PaymentMethods from '../payment/PaymentMethods';
import BagIcon from '../checkout/BagIcon';
import PropTypes from 'prop-types';

import styles from './StoreInfo.module.css';

/**
 * This component displays store hours, payment methods, location etc. 
 **/ 
const StoreInfoSidebar = ({hours_of_operation}) => {

  const { 
    ordersDisabled,
    defaultSlot, 
    timeSlotsByDay
  } = React.useContext(DeliveryOptionsContext);

  const getTodaysHours = () => {
    const dayOfWeek = daysOfWeek[new Date().getUTCDay()]; 
    const today = hours_of_operation.find(day => day.weekday === dayOfWeek); 
    return formattedHours(today);
  }

  const Pill = ({text, withStyle={}}) => {
    return (
      <span className={styles.pill} style={withStyle}>
        {text} 
      </span>
    ); 
  };

  const InfoItem = ({title, content, icon}) => {
    return (
      <div className={styles.infoItem}>
        <Grid container justifyContent='center' spacing={2}>
          <Grid item xs={2}>{icon}</Grid>
          <Grid item xs={10}>
            <div className={styles.gridText}>  
              <div className={styles.gridInfoHdr}>{title}</div>
              <div>{content}</div>
            </div> 
          </Grid>
        </Grid> 
      </div>
    )
  }

  return (
    <div className={styles.sideBarCard}>
      { hours_of_operation?.length > 0
        ? <>  
            <Header size={HeaderSize.Small} text="Today" withClass={styles.infoHdr}>
              { ordersDisabled
                ? <Pill text="CLOSED" withStyle={{backgroundColor:'var(--zrPromoRed)'}} />
                : <Pill text="OPEN" withStyle={{color:'#fff', backgroundColor:'var(--zrPrimaryDark)'}} />
              }
            </Header>  
            
            <Grid container>  
              <Grid item xs={4}>Hours:</Grid>
              <Grid item xs={8}>{getTodaysHours()}</Grid>
            </Grid>
            
            <Header size={HeaderSize.Small} withStyle={{marginTop:'2rem'}} text="Store Info" />
            
            {/* TODO: If closed, show slot for next day */}
            { defaultSlot > -1 && timeSlotsByDay.length
              ? <InfoItem title="NEXT PICKUP WINDOW" 
                          icon={<BagIcon fillColor="#555" size="36" />}
                          content={formattedHours(timeSlotsByDay[0][defaultSlot])} /> 
              : null
            }
            <InfoItem title="PAYMENT" 
                      icon={<img alt="" src="/img/localAtmIcon.png" style={{height:40}} />}
                      content={<PaymentMethods />} />
            <InfoItem title="MENUS" 
                      icon={<img alt="" src="/img/computerIcon.png" style={{height:40}} />}
                      content="Recreational" /> 
          </>
        : null
      }
    </div>
  );
};

StoreInfoSidebar.propTypes = {
  hours_of_operation: PropTypes.array.isRequired
}

export default StoreInfoSidebar;
