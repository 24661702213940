import React from 'react';

import { makeStyles } from '@material-ui/core';
import QuantityIcon from './QuantityIcon';
import { flattenedProduct } from '../products/product-utils';
import DisplayPrice from '../products/DisplayPrice';
import CartProductImage from '../products/CartProductImage';
import PropTypes from 'prop-types';

import styles from './CartItem.module.css';
import CartItemAvailabilityCheck from './CartItemAvailabilityCheck';

const useStyles = makeStyles({
  control: {
    backgroundColor: '#1879c3bb'
  },
  '&hover': {
    backgroundColor: 'var(--zrPrimaryDark)'
  } 
});

const CartItem = ({
  product,
  quantity,
  totalFlowerGrams,
  handleRemove,
  handleIncrease,
  handleDecrease
}) => {

  const classes = useStyles();
  
  const {
    id,
    name,
    category,
    image_url,
    flower_equivalent_grams,
  } = flattenedProduct(product);  

  return (
    <li className={styles.cartItem}>
      <div className={styles.itemDetails}>
        <span className={styles.itemCount}>x{quantity}</span>
        <span className={styles.itemTitle}>
          {name}
        </span>
        <span className={styles.itemPrice}>
          <DisplayPrice
            product={product}
            quantity={quantity} />
        </span>
      </div>

      <div className={styles.itemRemoveWrap}>
        <span className={styles.itemRemove} 
              style={{color: 'var(--zrPrimaryDark)'}}
              onClick={handleRemove}>
          Remove
        </span>
      </div>
      
      <div className={styles.imageWrap}>
        <CartProductImage imgUrl={image_url} imgAlt={name} imgClass={styles.itemImg} imgCategory={category?.display_name} />
        <QuantityIcon cartCount={quantity} productFlowerGrams={flower_equivalent_grams} totalFlowerGrams={totalFlowerGrams} />
        <span className={`${styles.itemControl} ${styles.increase} ${classes.control}`} onClick={handleIncrease}>+</span>
        <span className={`${styles.itemControl} ${styles.decrease} ${classes.control}`} onClick={handleDecrease}>-</span>
      </div>
      <CartItemAvailabilityCheck Id={id} handleRemove={handleRemove} />
    </li>
  )
}

CartItem.propTypes = {
  product: PropTypes.object.isRequired,
  quantity: PropTypes.number.isRequired,
  totalFlowerGrams: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  handleIncrease: PropTypes.func.isRequired,
  handleDecrease: PropTypes.func.isRequired
};

export default CartItem;
