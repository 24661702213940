import * as React from 'react';
import { dollarDisplay } from '../providers/OrderPricingProvider';
import { bundleItemTotalCents, discountedItemTotalCents } from '../checkout/CartItemsProvider';

const useProductPricing = (product, quantity) => {

  const [priceCents, setPriceCents] = React.useState();
  const [salePriceCents, setSalePriceCents] = React.useState();

  React.useEffect(() => {
    const { cost_usa_cents } = product?.display_info || {};
    if (cost_usa_cents) {
      setPriceCents(cost_usa_cents * quantity);
    }
    const salePrice = discountedItemTotalCents(product, quantity); 
    if ( quantity === 1 ) {
      // Sale Price
      setSalePriceCents(salePrice);
    } else {
      // Bundle Price or Sale Price
      setSalePriceCents(bundleItemTotalCents(product, quantity) || salePrice);
    }
  }, [product, quantity]);

  return {
    priceCents,
    priceDisplay: dollarDisplay(priceCents),
    salePriceCents,
    salePriceDisplay: salePriceCents && dollarDisplay(salePriceCents), // suppress 'N/A'
  }
}

export default useProductPricing;
