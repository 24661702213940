import * as React from 'react';

import { useAnalyticsStore } from '../../App';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import PropTypes from 'prop-types';

import styles from './AdjustQuantity.module.css';

/**
 * Adjust item quantity prior to adding to cart
 *
 * @param {number} count - initial/current item count from parent
 * @param {string} addlMessage - parent count setter
 */
const AdjustQuantity = ({
  count=1, 
  setCount,
  isStacked
}) => { 
  
  const { trackEvent } = useAnalyticsStore();

  const [displayQuantity, setDisplayQuantity] = React.useState(1);

  React.useEffect(() => {
    if (count !== displayQuantity) {
     setDisplayQuantity(count);
    } 
  }, [count, displayQuantity]);

  const increment = (event) => {
    event.stopPropagation();
    const newQuantity = displayQuantity + 1;
    setDisplayQuantity(newQuantity)
    setCount(newQuantity);
    trackEvent('product_modal_adjust_quantity_up');  
  }

  const decrement = (event) => {
    event.stopPropagation();
    if (displayQuantity > 1) {
      const newQuantity = displayQuantity - 1;
      setDisplayQuantity(newQuantity); 
      setCount(newQuantity);
      trackEvent('product_modal_adjust_quantity_down');  
    }
  }
  
  const alignClass = isStacked ? styles.stacked : '';

  return ( 
    <div className={`${styles.adjustQuantity} ${alignClass}`}>
      <div className={styles.control} onClick={event => decrement(event)}>
        <RemoveCircleOutlineRoundedIcon />
      </div>
      <div className={styles.countWrap}>
        <div className={styles.count}>
          {displayQuantity}
        </div>
      </div> 
      <div className={styles.control} onClick={event => increment(event)}>
        <AddCircleOutlineRoundedIcon />
      </div>
    </div>
  )
}

AdjustQuantity.propTypes = {
  count: PropTypes.number,  
  setCount: PropTypes.func.isRequired,
  isStacked: PropTypes.bool
}

export default AdjustQuantity;
