import config from "../../config";

export const devLog = (msg) => {
  if (config.IS_DEV || window.location.host.startsWith('localhost')) {
    if (msg.indexOf('error') > -1) {
      console.error(`DEV LOG: ${msg}`);
    } else {
      console.log(`DEV LOG: ${msg}`);
    }
  }
};

export const devLogError = (msg) => {
  if (config.IS_DEV || window.location.host.startsWith('localhost')) {
    console.error(`DEV LOG ERROR: ${msg}`);
  }
}; 

// For controllerRef use in useEffect hook
export const currentOrNewController = (controller) => {
  return controller?.signal && !controller.signal.aborted 
    ? controller
    : new AbortController();
}

// Return unaborted controller ref or new controller
export const activeController = (controllerRef) => {
  return controllerRef?.current &&
         !controllerRef.current.signal.aborted
    ? controllerRef.current
    : new AbortController();
};

// For product link sharing etc.
export const productLinkUsingSku = (dutchieSku) => {
  return `${window.location.protocol}//${window.location.host}/zrProduct/${dutchieSku}`;
}

// DATE HANDLING
export const isToday = (date) => {
  const today = new Date();
  return date.getDate() === today.getDate() &&
         date.getMonth() === today.getMonth() &&
         date.getFullYear() === today.getFullYear();
}
