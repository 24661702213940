import * as React from 'react';

import LabResultsForCard from './LabResultsForCard';
import { SEOLinkWrapper } from '../routing/router-utils';
import PreviewProductImage  from './PreviewProductImage';
import ProductName from './ProductName';
import InCartCheck from './InCartCheck';
import DisplayPriceOnCard from './DisplayPriceOnCard';
import BundlePricingTooltip from '../bundles/BundlePricingTooltip';
import BizOwnerTooltip from '../common/BizOwnerTooltip';
import SaleLabelBottom from './SaleLabelBottom';
import SoldOutRibbon from './SoldOutRibbon';
import Spinner from '../common/Spinner';
import PropTypes from 'prop-types';

import styles from './ProductPreviewCard.module.css';

const ProductPreviewCard = ({
  Id, 
  displayInfo,
  discount_data,
  per_order_max_quantity,
  quantity_available,
  display_sold_out_status,
  bundle_deal_data,
  tags,
  isSuggestedProduct,
  handleCartClick=()=>{},
  handleClick,
  deferImage, // for off-screen carousel cards
}) => {
  
  // The deep link for Google
  const deepLink = `/product/${Id}`;
  
  const {
    name:Name,
    image_url,
    cost_usa_cents,
    brand,
    category,
    cannabis_type,
    lab_results } = displayInfo;

  const showLabResults = lab_results?.length > 0;
     
  return (
    <>
      <div className={styles.productCardWrap}>  
        <SEOLinkWrapper deepLink={deepLink} styleClass={styles.productCard} handleClick={handleClick}>
          <div className={styles.productImageWrap}>
            { deferImage
              ? <div className={styles.spinnerWrap}>
                  <Spinner inSmallLayout />
                </div>
              : <PreviewProductImage 
                  src={image_url} 
                  category={category.display_name} 
                  productName={Name} 
                  isSuggestedProduct={isSuggestedProduct} />
            }
          </div>
          
          <div className={styles.productBody}>
            <div className={styles.productPrice}>
              <DisplayPriceOnCard
                cost_usa_cents={cost_usa_cents}
                bundle_deal_data={bundle_deal_data}
                discount_data={discount_data} />  
            </div>
  
            <div className={styles.productName}>
              <ProductName name={Name} cannabisType={cannabis_type} />
            </div>
            
            { showLabResults &&
              <div className={styles.productInfo}>
                <LabResultsForCard
                  productId={Id}
                  categoryName={category?.display_name}
                  lab_results={lab_results} />
              </div>
            }
  
            { brand &&
              <div className={styles.brand}>
                <span>{brand}</span>
                <BizOwnerTooltip
                  tags={tags}
                  businessName={brand} />
              </div>
            }
            { bundle_deal_data &&
              <BundlePricingTooltip
                cost_usa_cents={cost_usa_cents}
                bundle_deal_data={bundle_deal_data} />     
            }
          </div>
          <div className={styles.addedWrapper}>
            <InCartCheck 
              itemId={Id}
              quantity_available={quantity_available}
              display_sold_out_status={display_sold_out_status}
              handleCartClick={handleCartClick} />
          </div>
        </SEOLinkWrapper>
        <SoldOutRibbon 
          display_sold_out_status={display_sold_out_status}
          quantity_available={quantity_available} />
      </div>
        <SaleLabelBottom 
          cost_usa_cents={cost_usa_cents}
          discount_data={discount_data}
          bundle_deal_data={bundle_deal_data}
          per_order_max_quantity={per_order_max_quantity}
          isSuggestedProduct={isSuggestedProduct} />
    </>
  )
};

ProductPreviewCard.propTypes = {
  Id: PropTypes.string.isRequired,
  displayInfo: PropTypes.object.isRequired,
  discount_data: PropTypes.object,
  per_order_max_quantity: PropTypes.number,
  quantity_available: PropTypes.number.isRequired,
  display_sold_out_status: PropTypes.bool,
  tags: PropTypes.array.isRequired,
  isSuggestedProduct: PropTypes.bool,
  handleCartClick: PropTypes.func.isRequired, /* quick add icon */
  handleClick: PropTypes.func.isRequired,
  deferImage: PropTypes.bool, // for off-screen carousel cards
};

export default ProductPreviewCard;
