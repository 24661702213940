import React from 'react';

import { ModalContext, ModalType } from '../modal/ModalProvider';
import useBrandPartnerData from './useBrandPartnerData';
import { optimizedImage } from '../products/CroppedProductImage';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import PropTypes from 'prop-types';

import styles from './BrandHero.module.css';

const BrandHero = ({
  brandName
}) => {

  const { data } = useBrandPartnerData(brandName);
  const { displayModal } = React.useContext(ModalContext); 

  const iconStyle={
    fontSize: '20px',
    verticalAlign: -5,
    marginLeft: 4
  }

  return ( data.name
    ? <div className={styles.heroSection}>
        <div className={styles.heroWrap}>
          { data.hero && 
            <img alt={data.name} src={optimizedImage(data.hero, 1300)} className={styles.brandHero} />
          }
          { data.heroMobile &&
            <img alt={data.name} src={optimizedImage(data.heroMobile, 800)} className={`${styles.brandHero} ${styles.brandHeroMobile}`} />
          }
          { data.logo && 
            <img alt={data.name} src={optimizedImage(data.logo)} className={styles.brandLogo} />
          }
          <div className={styles.aboutLink}
               onClick={() => {
                 displayModal(ModalType.PARTNER_INFO,{
                   trackingContext: 'partner_info',
                   partnerName: brandName 
                 }) 
               }}>
            About {data.name}
            <InfoIcon style={iconStyle} />
          </div>
        </div>
      </div>
    : <></>  
  );
} 

BrandHero.propTypes = {
  brandName: PropTypes.string
};

export default BrandHero;
